import styled from "styled-components";
import Modal from "../../../UI/Modal";

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 24px;
    padding-bottom: 0;
    border: 0;
    border-radius: 12px;
  }

  .ant-modal-body {
    padding: 24px;
    padding-top: 20px;
    padding-bottom: 32px;
    border: 0;
  }

  .ant-modal-footer {
    padding: ${({ footer }) => (footer ? "24px" : "0")};
    padding-top: 0;
    border: 0;
    margin: 0;
  }

  .ant-modal-content {
    border-radius: 12px;
    padding: 0;
  }
`;
