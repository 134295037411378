import { BaseQueryParams } from "../baseQuery";

export const dashboardService = BaseQueryParams(
  "dashboardService",
).injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations/dashboard${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
    }),

    getProperties: builder.query({
      query: () => ({
        url: `/admin/migrations/dashboard/properties`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetDashboardQuery, useGetPropertiesQuery } = dashboardService;
