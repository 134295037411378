import { STORE_IMAGES } from "../constants/stores";

export const updateOptionItem = (options, Label) => {
  const items = options?.map((item) => ({
    value: item?.value,
    label: (
      <Label>
        <img
          src={
            STORE_IMAGES?.[item?.value?.toLowerCase()] ||
            STORE_IMAGES?.customcart
          }
          alt={item?.label}
        />{" "}
        {item?.label}
      </Label>
    ),
    picture: item?.picture,
  }));

  return items;
};
