import { BaseQueryParams } from "../baseQuery";

export const loyaltyAccountsService = BaseQueryParams(
  "loyaltyAccountsService",
  ["LOYALTY_ACCOUNTS", "USER_BY_ID"],
).injectEndpoints({
  endpoints: (builder) => ({
    getLoyaltyUsers: builder.query({
      query: ({ page, filters }) => ({
        url: `/profile/loyalty/users${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["LOYALTY_ACCOUNTS"],
    }),

    deleteLoyaltyUser: builder.mutation({
      query: (id) => ({
        url: `/profile/loyalty/users/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["LOYALTY_ACCOUNTS"],
    }),

    createLoyaltyUser: builder.mutation({
      query: (body) => ({
        url: "/profile/loyalty/users",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["LOYALTY_ACCOUNTS"],
    }),

    editLoyaltyUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `/partners/users/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["LOYALTY_ACCOUNTS"],
    }),

    getLoyaltyUsersById: builder.query({
      query: ({ filters }) => ({
        url: "/profile/loyalty/users",
        method: "GET",
        params: filters,
      }),
    }),
  }),
});

export const {
  useGetLoyaltyUsersQuery,
  useDeleteLoyaltyUserMutation,
  useCreateLoyaltyUserMutation,
  useEditLoyaltyUserMutation,
  useGetLoyaltyUsersByIdQuery,
} = loyaltyAccountsService;
