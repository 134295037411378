import { BaseQueryParams } from "../baseQuery";

export const migrationSurveysService = BaseQueryParams("migrationSurveys", [
  "QUESTIONS",
  "ANSWERS",
  "SURVEYS",
]).injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/quiz/questions${page ? `?page=${page}` : ""}${
          per_page ? `&per_page=${per_page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["QUESTIONS"],
    }),

    deleteQuestion: builder.mutation({
      query: (id) => ({
        url: `/admin/quiz/questions/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["QUESTIONS"],
    }),

    createQuestion: builder.mutation({
      query: (body) => ({
        url: "/admin/quiz/questions",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["QUESTIONS"],
    }),

    editQuestion: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/quiz/questions/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["QUESTIONS"],
    }),

    getAnswers: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/quiz/answers${page ? `?page=${page}` : ""}${
          per_page ? `&per_page=${per_page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["ANSWERS"],
    }),

    createAnswer: builder.mutation({
      query: (body) => ({
        url: "/admin/quiz/answers",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["ANSWERS"],
    }),

    deleteAnswers: builder.mutation({
      query: (id) => ({
        url: `/admin/quiz/answers/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["ANSWERS"],
    }),

    editAnswer: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/quiz/answers/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["ANSWERS"],
    }),

    getSurveys: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/quiz${page ? `?page=${page}` : ""}${
          per_page ? `&per_page=${per_page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["SURVEYS"],
    }),

    getExportData: builder.query({
      query: () => ({
        url: "/admin/quiz/export?migration_ids&date_added&data&firstname&lastname&email",
        method: "GET",
      }),
    }),

    deleteOldSurveys: builder.mutation({
      query: () => ({
        url: "/admin/quiz/old",
        method: "Delete",
      }),

      invalidatesTags: () => ["SURVEYS"],
    }),

    getConfig: builder.query({
      query: () => ({
        url: "/admin/quiz/config",
        method: "GET",
      }),
    }),

    getAnswersForQuestion: builder.query({
      query: ({ question }) => ({
        url: `/admin/quiz/questions/answers/${question}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useDeleteQuestionMutation,
  useCreateQuestionMutation,
  useEditQuestionMutation,
  useGetAnswersQuery,
  useCreateAnswerMutation,
  useDeleteAnswersMutation,
  useEditAnswerMutation,
  useGetSurveysQuery,
  useGetExportDataQuery,
  useDeleteOldSurveysMutation,
  useGetConfigQuery,
  useGetAnswersForQuestionQuery,
} = migrationSurveysService;
