import React from "react";

import { Container, AntdDrawer } from "./styles";

const Drawer = ({ onClose, open, menu, ...rest }) => (
  <Container>
    <AntdDrawer
      title="Menu"
      placement="left"
      onClose={onClose}
      open={open}
      {...rest}
    >
      {menu}
    </AntdDrawer>
  </Container>
);

export default Drawer;
