export const termsLink =
  "https://www.shopping-cart-migration.com/terms-of-service";

export const compliance =
  "https://www.shopping-cart-migration.com/gdpr-compliance";

export const SECURITY_POLICY =
  "https://www.shopping-cart-migration.com/security-policy";

export const downloadBridge =
  "https://app.shopping-cart-migration.com//migrations/wizard/bridge";

export const BRIDGE_INSTRUCTION =
  "https://www.shopping-cart-migration.com/faq/10-general-questions/47-what-are-the-connection-bridge-files-and-how-to-download-them";

export const MIGRATION_INSURANCE =
  "https://www.shopping-cart-migration.com/faq/60-migration-insurance/218-why-do-i-need-to-clear-current-data-from-the-target-store-in-order-to-restart-migration";

export const API_DISABLE_NOTIFICATION =
  "https://www.shopping-cart-migration.com/faq/22-shopify/291-how-to-disable-notifications-about-new-orders-on-shopify";

export const DEMO_MIGRATION_REVIEWS =
  "https://app.shopping-cart-migration.com/migrations/download/resources/id/291056/resource/reviewsWriter";

export const DEMO_MIGRATION_PRODUCT_REVIEWS =
  "https://app.shopping-cart-migration.com/migrations/download/resources/id/291056/resource/reviewsWriter";

export const DEMO_MIGRATION_REVIEWS_INSTRUCTION =
  "https://www.shopping-cart-migration.com//faq/22-Shopify/393-can-i-migrate-reviews-to-shopify";

export const CONTACT_SUPPORT_TEAM =
  "https://support.magneticone.com/hc/en-us/requests/new";

export const APP_LINK = "https://app.shopping-cart-migration.com";

export const LOYALTY_PROGRAM_LINK =
  "https://shopping-cart-migration.com/loyalty-program";

export const CONTACT_US = "https://www.shopping-cart-migration.com/contact-us";
