import React, { memo } from "react";
import { Controller } from "react-hook-form";

import AuthButton from "../AuthButton";
import { Container, Wrapper, StyledCheckbox, Link } from "./styles";

const AuthLoginFooter = ({
  onSubmit,
  isValid,
  isLoading,
  goToForgotPassword,
  saveAuth,
  control,
}) => (
  <Container>
    <Wrapper>
      <Controller
        control={control}
        name="saved"
        render={({ field }) => (
          <StyledCheckbox
            {...field}
            checked={field.value}
            onChange={(e) => {
              field.onChange(e);
              saveAuth();
            }}
          >
            Stay Signed In
          </StyledCheckbox>
        )}
      />

      <Link onClick={goToForgotPassword}>Forgot password?</Link>
    </Wrapper>

    <AuthButton onSubmit={onSubmit} isLoading={isLoading} isValid={isValid}>
      Sign in
    </AuthButton>
  </Container>
);

export default memo(AuthLoginFooter);
