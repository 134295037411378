import { BaseQueryParams } from "../baseQuery";

export const migrationInstancesService = BaseQueryParams(
  "migrationInstancesService",
  ["MIGRATION_INSTANCES"],
).injectEndpoints({
  endpoints: (builder) => ({
    getMigrationInstances: builder.query({
      query: () => ({
        url: `/admin/migrations/instances`,
        method: "GET",
      }),

      providesTags: () => ["MIGRATION_INSTANCES"],
    }),

    getMigrationInstancesInfo: builder.query({
      query: ({ publicIp }) => ({
        url: `/admin/migrations/instances/docker-info/${publicIp}`,
        method: "GET",
      }),
    }),

    deleteMigrationInstance: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/instances/stop/${id}`,
        method: "POST",
      }),

      invalidatesTags: () => ["MIGRATION_INSTANCES"],
    }),
  }),
});

export const {
  useGetMigrationInstancesQuery,
  useGetMigrationInstancesInfoQuery,
  useDeleteMigrationInstanceMutation,
} = migrationInstancesService;
