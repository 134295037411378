import React from "react";

import { InputsRules } from "../../../../constants/auth";
import AuthInput from "../AuthInput";
import AuthButton from "../AuthButton";

import { Container, Placeholder } from "./styles";

const AuthForgotPasswordForm = ({
  errors,
  control,
  onSubmit,
  isValid,
  isLoading,
}) => (
  <>
    <Container>
      <AuthInput
        error={errors?.email?.message}
        control={control}
        label="Email"
        name="email"
        rules={{
          required: InputsRules.required,
          pattern: InputsRules.patternEmail,
        }}
      />

      {!errors?.email?.message && (
        <Placeholder>
          The password recovery instructions will be sent to the email address
          you have provided.
        </Placeholder>
      )}
    </Container>

    <AuthButton onSubmit={onSubmit} isLoading={isLoading} isValid={isValid}>
      Reset Password
    </AuthButton>
  </>
);

export default AuthForgotPasswordForm;
