import { BaseQueryParams } from "../baseQuery";

export const permissionsService = BaseQueryParams("permissionsService", [
  "PERMISSIONS",
]).injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/profile/permissions${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["PERMISSIONS"],
    }),

    deletePermission: builder.mutation({
      query: (id) => ({
        url: `/admin/profile/permission/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["PERMISSIONS"],
    }),

    createPermission: builder.mutation({
      query: (body) => ({
        url: "/admin/profile/permission",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PERMISSIONS"],
    }),

    editPermission: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/profile/permission/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["PERMISSIONS"],
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useDeletePermissionMutation,
  useCreatePermissionMutation,
  useEditPermissionMutation,
} = permissionsService;
