import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "antd";

export const Container = styled.div`
  gap: 0 24px;
  width: 100%;

  .ant-collapse {
    width: 100%;
    border: none;
    background-color: ${({ theme }) => theme.pallette.silver.main};
  }

  .ant-collapse-content-box {
    background-color: ${({ theme }) => theme.pallette.silver.main};
  }

  .ant-collapse-header-text {
    color: ${({ theme }) => theme.pallette.blue.primary};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .ant-collapse-item:last-child {
    border: none;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.pallette.blue.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.5s ease;
  gap: 0 5px;
  display: flex;
  align-items: center;
  justify-content: ${({ accordion }) => (accordion ? "center" : "left")};
  margin: ${({ accordion }) => (accordion ? "0 0 0 10px" : "0")};
  padding: 10px 0;

  svg {
    path {
      stroke: ${({ theme }) => theme.pallette.blue.primary};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.pallette.blue.light};

    svg {
      path {
        stroke: ${({ theme }) => theme.pallette.blue.light};
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.pallette.blue.primary};
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    text-align: left;
  }
`;
