import React from "react";

import { CustomLink } from "./styles";

const DefaultMap = ({ error, variables, dangerouslySetInnerHTML }) => {
  const replacedMessage = error?.replace(`{{${variables?.link?.type}}}`, "");

  if (error?.indexOf("{{link}}") === -1) {
    <div dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} />;
  }

  return (
    <CustomLink>
      <div dangerouslySetInnerHTML={{ __html: replacedMessage }} />
    </CustomLink>
  );
};

export default DefaultMap;
