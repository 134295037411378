import React from "react";
import ModalCheckBoxGroup from "../ModalCheckBoxGroup";
import { ReactComponent as ArrowIcon } from "../../../assets/images/dropArrow.svg";

import { StyledDropdown, StyledDiv } from "./styles";

const ShowColsDropdown = ({ control, options, children, name }) => (
  <StyledDropdown
    placement="bottom"
    getPopupContainer={(trigger) => trigger}
    autoAdjustOverflow={false}
    dropdownRender={() => (
      <ModalCheckBoxGroup control={control} name={name} options={options} />
    )}
  >
    <StyledDiv>
      {children}

      <ArrowIcon />
    </StyledDiv>
  </StyledDropdown>
);

export default ShowColsDropdown;
