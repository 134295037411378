import { BaseQueryParams } from "../baseQuery"

export const errorsStatisticsService = BaseQueryParams("errorsStatisticsService",).injectEndpoints({
  endpoints: (builder) => ({
    getErrorsStatistics: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/error-statistics?${page ? `page=${page}`: ""}&config=1`,
        method: "GET",
        params: filters,
      }),
    }),
  })
})

export const {
  useGetErrorsStatisticsQuery,
} = errorsStatisticsService
