import React from "react";
import { useLoadingModal } from "../../hooks/useLoadingModal";
import LoadingModal from "../../components/LoadingModal";

const LoadingModalProvider = ({ children }) => {
  const isLoading = useLoadingModal();

  return (
    <>
      <LoadingModal isLoading={isLoading} />

      {children}
    </>
  );
};

export default LoadingModalProvider;
