import React, { useEffect } from "react";

import { useGetUserQuery } from "../../store/authentication/authService";
import { useAuth } from "../../hooks/useAuth";
import { useGtmEvent } from "../../hooks/useGtmEvent";
import { AUTH_GTM_EVENTS } from "../../constants/auth-gtm-events";

import LoadingModal from "../../components/LoadingModal";

const SessionProvider = ({ children }) => {
  const { access_token, user } = useAuth();
  const { sendGtmEvent } = useGtmEvent();

  const { isLoading } = useGetUserQuery({}, { skip: !access_token });

  useEffect(() => {
    if (!isLoading && user?.has_migrations && user?.id) {
      sendGtmEvent(AUTH_GTM_EVENTS.login);
    }
  }, [isLoading, user?.has_migrations, user?.id]);

  useEffect(() => {
    if (!isLoading && !user?.has_migrations && user?.id) {
      sendGtmEvent(AUTH_GTM_EVENTS.registration);
    }
  }, [isLoading, user?.has_migrations, user?.id]);

  return (
    <>
      <LoadingModal isLoading={isLoading} />

      {children}
    </>
  );
};

export default SessionProvider;
