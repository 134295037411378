import { BaseQueryParams } from "../baseQuery";

export const adminFilterService = BaseQueryParams(
  "adminFilter",
).injectEndpoints({
  endpoints: (builder) => ({
    getTestData: builder.query({
      query: ({ filters }) => ({
        url: `/admin/test/grid?config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["adminFilter"],
    }),
  }),
});

export const { useGetTestDataQuery } = adminFilterService;

export const selectDataForSelect = (state) => state.filter.dataForSelect;
