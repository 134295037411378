import React from "react";
import { Progress } from "antd";
import { creditToDolars } from "../../../modules/Admin/BalanceHistory/helpers";

import { Container, Wrapper, Title, TitleBold } from "./styles";

const ProgressProfile = ({ percent, balance }) => (
  <Container>
    <Wrapper>
      <TitleBold>{percent ?? 0}%</TitleBold>
      <Title>profile completeness</Title>
    </Wrapper>

    <Progress percent={+percent ?? 0} showInfo={false} />

    <Wrapper>
      <Title>Your Balance:</Title>
      <TitleBold>${creditToDolars(balance) ?? 0}</TitleBold>
    </Wrapper>
  </Container>
);

export default ProgressProfile;
