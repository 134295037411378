import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled.div`
  display: flex;
`;

export const AntdDrawer = styled(Drawer)`
  .ant-drawer-body {
    & > div {
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;

      a {
        color: ${({ theme }) => theme.pallette.blue.primary};

        &:hover {
          color: ${({ theme }) => theme.pallette.blue.light};
        }
      }
    }

    ul {
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;

      a {
        color: ${({ theme }) => theme.pallette.blue.primary};

        &:hover {
          color: ${({ theme }) => theme.pallette.blue.light};
        }
      }
    }
  }

  display: none;

  @media (max-width: 1024px) {
    display: flex;
  }
`;
