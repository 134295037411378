import styled from "styled-components";

export const Container = styled.div`
  gap: 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 24px;
  border-radius: 8px 8px 0px 0px;
`;

export const Wrapper = styled.div`
  gap: 30px 40px;
  width: ${({ arrows }) => (arrows ? "0 16px" : "0 40px")};
  display: flex;
  flex-direction: ${({ longheader }) => (longheader ? "column" : "row")};
`;

export const MainWrapper = styled.div`
  gap: 0 40px;
  width: ${({ arrows }) => (arrows ? "0 16px" : "0 40px")};
  display: flex;
  flex-direction: row;
`;
