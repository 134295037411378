import { BaseQueryParams } from "../baseQuery";

export const smartMappingService = BaseQueryParams("smartMapping", [
  "MAPPING",
]).injectEndpoints({
  endpoints: (builder) => ({
    getMappings: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/smart-mapping${page ? `?page=${page}` : ""}${
          per_page ? `&per_page=${per_page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["MAPPING"],
    }),

    deleteMapping: builder.mutation({
      query: (id) => ({
        url: `/admin/smart-mapping/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["MAPPING"],
    }),

    createMapping: builder.mutation({
      query: (body) => ({
        url: "/admin/smart-mapping",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MAPPING"],
    }),

    editMapping: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/smart-mapping/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["MAPPING"],
    }),

    getEntityTypes: builder.query({
      query: () => ({
        url: "/admin/smart-mapping/entity-types",
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    getMappingDemo: builder.query({
      query: ({ mappingData }) => ({
        url: "/admin/smart-mapping/demo",
        method: "GET",
        params: mappingData,
      }),

      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetMappingsQuery,
  useDeleteMappingMutation,
  useCreateMappingMutation,
  useEditMappingMutation,
  useGetEntityTypesQuery,
  useGetMappingDemoQuery,
} = smartMappingService;
