import styled from "styled-components";
import { Checkbox } from "antd";

export const Container = styled.div`
  width: 100%;
  max-width: 584px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.pallette.silver.primary};
  }
`;

export const Link = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.link};
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
