import React, { memo } from "react";

import { Container, Title, SubTitle } from "./styles";

const HeaderMigrationInfo = ({ title, subTitle }) => (
  <Container>
    <Title>{title}</Title>

    <SubTitle>{subTitle}</SubTitle>
  </Container>
);

export default memo(HeaderMigrationInfo);
