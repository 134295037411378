import styled from "styled-components";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  margin: 10px auto;
  padding: 0 20px;
  width: fit-content;
  background: ${({ theme }) => theme.pallette.green.light};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.pallette.blue.primary};
  border: none;

  &.ant-btn-default {
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${({ theme }) => theme.pallette.green.secondary};
      border: none;
      color: ${({ theme }) => theme.pallette.white.main};
    }

    &:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${({ theme }) => theme.pallette.green.light};
      border: none;
      color: ${({ theme }) => theme.pallette.blue.primary};
    }
  }
`;
export const Ul = styled.ul`
  display: inline-block;
  text-align: left;
  font-size: 14px;
  list-style: none;

  li {
    margin: 5px;
  }

  span {
    width: calc(100% - 20px);
  }
  svg {
    margin: 0 5px -5px 0;
    path {
      stroke: ${({ theme }) => theme.pallette.blue.primary};
    }
  }
`;
export const ErrorNotice = styled.div`
  color: ${({ theme }) => theme.pallette.blue.primary};
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-weight: 500;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.pallette.blue.primary};
  text-decoration: underline;
  margin: 0 5px;
  font-size: 14px;
  line-height: 24px;

  &:hover {
    text-decoration: none;
  }
`;