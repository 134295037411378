import React from "react";

import FooterDropdownLink from "../FooterDropdownLink";
import SocialLink from "../SocialLink";

import {
  Container,
  WrapperAddress,
  Address,
  Title,
  Phone,
  Email,
} from "./styles";

const ContactInfo = () => (
  <Container>
    <FooterDropdownLink />

    <WrapperAddress>
      <SocialLink />

      <Address>
        <Title>
          Toll-Free <Phone href="tel:1-800-224-1462">1-800-224-1462</Phone>
        </Title>

        <Email href="mailto:contact@shopping-cart-migration.com">
          contact@shopping-cart-migration.com
        </Email>
      </Address>
    </WrapperAddress>
  </Container>
);

export default ContactInfo;
