import React, { useState, useEffect } from "react";

const DynamicSvgActions = ({ imageName, path = "actions" }) => {
  const [svgComponent, setSvgComponent] = useState(null);

  useEffect(() => {
    const importSVG = async () => {
      try {
        const { default: svg } = await import(
          `../../../assets/images/${path}/${imageName}.svg`
        );
        setSvgComponent(() => svg);
      } catch (error) {
        console.error("Помилка завантаження SVG:", error);
      }
    };

    importSVG();
  }, [imageName]);

  return <img src={svgComponent} alt="icon" />;
};

export default DynamicSvgActions;
