import React from "react";
import { Collapse } from "antd";

import { Container, StyledLink } from "./styles";

export const getFooterAccordionItems = (items) =>
  items?.map((item) => ({
    key: item.title,
    label: item.title,
    children: item?.items?.map((link) => (
      <StyledLink {...link?.label?.props} key={link?.key}>
        {link?.label?.props?.children?.props?.children}
      </StyledLink>
    )),
  }));

const FooterAccordion = ({ items }) => (
  <Container>
    <Collapse accordion items={getFooterAccordionItems(items)} />
  </Container>
);

export default FooterAccordion;
