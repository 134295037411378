import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.pallette.white.main};
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.5s ease;

  &:hover {
    color: ${({ theme }) => theme.pallette.blue.light};
  }
`;

export const StyledButton = styled(Link)`
  height: auto;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;
  font-size: 14px;
  color: ${({ theme }) => theme.pallette.green.light};
  border: 1px solid ${({ theme }) => theme.pallette.green.light};
  border-radius: 100px;
  background: ${({ theme }) => theme.pallette.blue.main};

  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.pallette.white.main};
      }
    }
    border: 1px solid ${({ theme }) => theme.pallette.blue.main};
    color: ${({ theme }) => theme.pallette.white.main};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 24px;
`;
