import { BaseQueryParams } from "../baseQuery";

export const migrationInsurancesService = BaseQueryParams(
  "migrationInsurancesService",
  ["MIGRATION_INSURANCES"],
).injectEndpoints({
  endpoints: (builder) => ({
    getInsurances: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/configurations/migrations/insurances${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
      providesTags: () => ["MIGRATION_INSURANCES"],
    }),

    deleteInsurance: builder.mutation({
      query: (id) => ({
        url: `/admin/configurations/migrations/insurances/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["MIGRATION_INSURANCES"],
    }),

    createInsurance: builder.mutation({
      query: (body) => ({
        url: "/admin/configurations/migrations/insurances",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_INSURANCES"],
    }),

    editInsurance: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/configurations/migrations/insurances/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_INSURANCES"],
    }),
  }),
});

export const {
  useGetInsurancesQuery,
  useDeleteInsuranceMutation,
  useEditInsuranceMutation,
  useCreateInsuranceMutation,
} = migrationInsurancesService;
