import { WIZARD_STEP_PATH } from "../router/path/wizard";

export const WIZARD_STATES = {
  done: "done",
  next: "next",
  active: "active",
  finish: "finish",
};

export const WIZARD_ACTIONS = {
  previous: "prev",
  next: "next",
};

export const WIZARD_STEPS_ADMIN = [
  {
    id: WIZARD_STEP_PATH.ACCOUNT_SELECTION,
    status: WIZARD_STATES.active,
    name: "ACCOUNT SELECTION",
    stepNumber: 1,
  },
  {
    id: WIZARD_STEP_PATH.SELECT_SOURCE,
    status: WIZARD_STATES.next,
    name: "SELECT SOURCE",
    stepNumber: 2,
  },
  {
    id: WIZARD_STEP_PATH.SOURCE_CONNECTION,
    status: WIZARD_STATES.next,
    name: "SOURCE CONNECTION",
    stepNumber: 3,
  },
  {
    id: WIZARD_STEP_PATH.SELECT_TARGET,
    status: WIZARD_STATES.next,
    name: "SELECT TARGET",
    stepNumber: 4,
  },
  {
    id: WIZARD_STEP_PATH.TARGET_CONNECTION,
    status: WIZARD_STATES.next,
    name: "TARGET CONNECTION",
    stepNumber: 5,
  },
  {
    id: WIZARD_STEP_PATH.ENTITIES_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT ENTITIES FOR MIGRATION",
    stepNumber: 6,
  },
  {
    id: WIZARD_STEP_PATH.MAPPING_SETUP,
    status: WIZARD_STATES.next,
    name: "MAPPING SETUP",
    stepNumber: 7,
  },
  {
    id: WIZARD_STEP_PATH.OPTIONS_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT DESIRABLE ADDITIONAL OPTIONS",
    stepNumber: 8,
  },
  {
    id: WIZARD_STEP_PATH.DEMO_MIGRATION,
    status: WIZARD_STATES.next,
    name: "DEMO MIGRATION CHECK",
    stepNumber: 9,
  },
  {
    id: WIZARD_STEP_PATH.FINISH,
    status: WIZARD_STATES.finish,
    name: "FINISH",
    stepNumber: 10,
  },
];

export const WIZARD_STEPS_ADMIN_RECENT = [
  {
    id: WIZARD_STEP_PATH.ACCOUNT_SELECTION,
    status: WIZARD_STATES.active,
    name: "ACCOUNT SELECTION",
    stepNumber: 1,
  },
  {
    id: WIZARD_STEP_PATH.SELECT_SOURCE,
    status: WIZARD_STATES.next,
    name: "SELECT SOURCE",
    stepNumber: 2,
  },
  {
    id: WIZARD_STEP_PATH.SOURCE_CONNECTION,
    status: WIZARD_STATES.next,
    name: "SOURCE CONNECTION",
    stepNumber: 3,
  },
  {
    id: WIZARD_STEP_PATH.SELECT_TARGET,
    status: WIZARD_STATES.next,
    name: "SELECT TARGET",
    stepNumber: 4,
  },
  {
    id: WIZARD_STEP_PATH.TARGET_CONNECTION,
    status: WIZARD_STATES.next,
    name: "TARGET CONNECTION",
    stepNumber: 5,
  },
  {
    id: WIZARD_STEP_PATH.ENTITIES_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT ENTITIES FOR MIGRATION",
    stepNumber: 6,
  },
  {
    id: WIZARD_STEP_PATH.MAPPING_SETUP,
    status: WIZARD_STATES.next,
    name: "MAPPING SETUP",
    stepNumber: 7,
  },
  {
    id: WIZARD_STEP_PATH.OPTIONS_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT DESIRABLE ADDITIONAL OPTIONS",
    stepNumber: 8,
  },
  {
    id: WIZARD_STEP_PATH.FINISH,
    status: WIZARD_STATES.finish,
    name: "FINISH",
    stepNumber: 9,
  },
];

export const WIZARD_STEPS_USER = [
  {
    id: WIZARD_STEP_PATH.SELECT_SOURCE,
    status: WIZARD_STATES.next,
    name: "SELECT SOURCE",
    stepNumber: 1,
  },
  {
    id: WIZARD_STEP_PATH.SOURCE_CONNECTION,
    status: WIZARD_STATES.next,
    name: "SOURCE CONNECTION",
    stepNumber: 2,
  },
  {
    id: WIZARD_STEP_PATH.SELECT_TARGET,
    status: WIZARD_STATES.next,
    name: "SELECT TARGET",
    stepNumber: 3,
  },
  {
    id: WIZARD_STEP_PATH.TARGET_CONNECTION,
    status: WIZARD_STATES.next,
    name: "TARGET CONNECTION",
    stepNumber: 4,
  },
  {
    id: WIZARD_STEP_PATH.ENTITIES_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT ENTITIES FOR MIGRATION",
    stepNumber: 5,
  },
  {
    id: WIZARD_STEP_PATH.MAPPING_SETUP,
    status: WIZARD_STATES.next,
    name: "MAPPING SETUP",
    stepNumber: 6,
  },
  {
    id: WIZARD_STEP_PATH.OPTIONS_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT DESIRABLE ADDITIONAL OPTIONS",
    stepNumber: 7,
  },
  {
    id: WIZARD_STEP_PATH.DEMO_MIGRATION,
    status: WIZARD_STATES.next,
    name: "DEMO MIGRATION CHECK",
    stepNumber: 8,
  },
  {
    id: WIZARD_STEP_PATH.FINISH,
    status: WIZARD_STATES.finish,
    name: "FINISH",
    stepNumber: 9,
  },
];

export const WIZARD_STEPS_USER_RECENT = [
  {
    id: WIZARD_STEP_PATH.SELECT_SOURCE,
    status: WIZARD_STATES.next,
    name: "SELECT SOURCE",
    stepNumber: 1,
  },
  {
    id: WIZARD_STEP_PATH.SOURCE_CONNECTION,
    status: WIZARD_STATES.next,
    name: "SOURCE CONNECTION",
    stepNumber: 2,
  },
  {
    id: WIZARD_STEP_PATH.SELECT_TARGET,
    status: WIZARD_STATES.next,
    name: "SELECT TARGET",
    stepNumber: 3,
  },
  {
    id: WIZARD_STEP_PATH.TARGET_CONNECTION,
    status: WIZARD_STATES.next,
    name: "TARGET CONNECTION",
    stepNumber: 4,
  },
  {
    id: WIZARD_STEP_PATH.ENTITIES_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT ENTITIES FOR MIGRATION",
    stepNumber: 5,
  },
  {
    id: WIZARD_STEP_PATH.MAPPING_SETUP,
    status: WIZARD_STATES.next,
    name: "MAPPING SETUP",
    stepNumber: 6,
  },
  {
    id: WIZARD_STEP_PATH.OPTIONS_SELECTION,
    status: WIZARD_STATES.next,
    name: "SELECT DESIRABLE ADDITIONAL OPTIONS",
    stepNumber: 7,
  },
  {
    id: WIZARD_STEP_PATH.FINISH,
    status: WIZARD_STATES.finish,
    name: "FINISH",
    stepNumber: 8,
  },
];

export const SOURCE_STORE_RADIO_BUTTONS = [
  {
    name: "Bridge",
    value: "bridge",
  },
  {
    name: "FTP/SSH",
    value: "ftp",
  },
];

export const TYPE_CART_CONNECT = {
  CONNECT_BRIDGE: "bridge",
  CONNECT_FTP: "ftp",
  CONNECT_API: "api",
  CONNECT_FILE: "file",
};

export const treeData = [
  {
    title: "Select All",
    key: "select_all",
    children: [
      {
        title: "Products",
        key: "products",
        children: [
          {
            title: "Reviews",
            key: "reviews",
          },
          {
            title: "Categories",
            key: "categories",
          },
          {
            title: "Manufactures",
            key: "manufactures",
          },
          {
            title: "Taxes",
            key: "taxes",
          },
        ],
      },
      {
        title: "Customers",
        key: "customers",
        children: [
          {
            title: "Orders",
            key: "orders",
          },
        ],
      },
      {
        title: "Coupons",
        key: "coupons",
      },
      {
        title: "CMS Pages",
        key: "cms_pages",
      },
    ],
  },
];
