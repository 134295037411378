import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  color: ${({ theme }) => theme.pallette.dark.main};
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.pallette.silver.primary};
  margin: 8px 0 0 0;
`;

export const Link = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.link};
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;
