import React, { memo, useEffect } from "react";
import { notification } from "antd";

import { useNavigate } from "react-router";
import { useNotification } from "../../../hooks/useNotification";
import { ReactComponent as NoticeErrorIcon } from "../../../assets/images/NoticeErrorIcon.svg";
import ErrorMapContent from "../../CustomErrorMessages/ErrorMapContent";
import SupportError from "../../CustomErrorMessages/SupportError";

import { Container, Message, Description } from "./styles";

const Notification = () => {
  const navigate = useNavigate();
  const noticeData = useNotification();
  const [api, contextHolder] = notification.useNotification();

  const style = {
    width: 653,
    borderRadius: 8,
    border: "1px solid #FCA5A5",
    background: "#FEF3F2",
    padding: "16px",
  };

  const openNotificationWithIcon = (typeImg) => {
    api[typeImg]({
      message: (
        <Message>
          {noticeData?.details?.title ??
            noticeData?.data?.error?.details?.title ??
            noticeData.status}
        </Message>
      ),
      description: (
        <>
          <Description>
            <ErrorMapContent
              error={noticeData?.data?.error?.message}
              variables={noticeData?.data?.error?.details.variables}
              dangerouslySetInnerHTML={
                noticeData?.message ??
                noticeData?.error ??
                noticeData?.data?.error?.message
              }
              onClose={notification.destroy}
            />
          </Description>
          <SupportError faqLink={noticeData?.data?.error?.details?.faq} />
        </>
      ),
      duration: 0,

      style,
      icon: <NoticeErrorIcon />,
    });
  };

  useEffect(() => {
    if (noticeData) {
      openNotificationWithIcon("error");
    } else {
      api.destroy();
    }
  }, [noticeData]);

  useEffect(() => {
    if (noticeData?.data?.error?.details?.redirect_path) {
      setTimeout(() => {
        navigate(noticeData?.data?.error?.details?.redirect_path);
      }, 3000);
    }
  }, [noticeData?.data?.error?.details?.redirect_path]);

  useEffect(() => {
    if (noticeData?.data?.error?.details?.redirect_url) {
      navigate(noticeData?.data?.error?.details?.redirect_url);
    }
  }, [noticeData?.data?.error?.details?.redirect_url]);

  return <Container>{contextHolder}</Container>;
};

export default memo(Notification);
