import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-bottom: 32px;

  .ant-radio-group {
    display: flex;
    flex-direction: ${({ $modalType }) => ($modalType ? "column" : "row")};
    width: ${({ $modalType }) => ($modalType ? "100%" : "auto")};
    gap: ${({ $modalType }) => ($modalType ? "16px" : "32px")};
  }
`;

export const Error = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.red.error};
`;
