import { createSlice } from "@reduxjs/toolkit";

import { authService } from "./authService";
import { settingsService } from "../settings/settingsService";

const initialState = {
  user: null,
  access_token: "",
  isLoading: false,
  activeTab: "1",
  showDrawer: false,
  baseUrl: localStorage.getItem("host_api"),
  isLoadingCreate: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    setIsLoadingCreate: (state, { payload }) => {
      state.isLoadingCreate = payload;
    },

    setNewTab: (state, { payload }) => {
      state.activeTab = payload;
    },

    setToken: (state, { payload }) => {
      state.access_token = payload;
    },

    setShowDrawer: (state, action) => {
      state.showDrawer = action.payload;
    },

    setBaseUrl: (state, { payload }) => {
      state.baseUrl = payload;
      if (!payload) {
        state.baseUrl = process.env.REACT_APP_HOST_API;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      authService.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.access_token = payload?.payload?.access_token;
      },
    );

    builder.addMatcher(
      authService.endpoints.loginAsUser.matchFulfilled,
      (state, { payload }) => {
        state.access_token = payload?.payload?.access_token;
      },
    );

    builder.addMatcher(
      authService.endpoints.socialLoginCallback.matchFulfilled,
      (state, { payload }) => {
        state.access_token = payload?.payload?.access_token;
      },
    );

    builder.addMatcher(
      authService.endpoints.simpleRegister.matchFulfilled,
      (state, { payload }) => {
        state.access_token = payload?.payload?.access_token;
      },
    );

    builder.addMatcher(authService.endpoints.logout.matchFulfilled, (state) => {
      state.access_token = "";
      state.user = null;
    });

    builder.addMatcher(
      authService.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        if (!payload.payload?.id) {
          state.access_token = null;
          state.user = null;

          return;
        }

        state.user = payload?.payload;
      },
    );

    builder.addMatcher(authService.endpoints.getUser.matchRejected, (state) => {
      state.access_token = null;
      state.user = null;
    });

    builder.addMatcher(
      authService.endpoints.updateProfile.matchFulfilled,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      authService.endpoints.updateProfile.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      authService.endpoints.updateProfile.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      authService.endpoints.addAvatar.matchFulfilled,
      (state, { payload }) => {
        state.user = payload?.payload;
      },
    );

    builder.addMatcher(
      authService.endpoints.deleteAvatar.matchFulfilled,
      (state, { payload }) => {
        state.user = payload?.payload;
      },
    );

    builder.addMatcher(
      authService.endpoints.setBusinessRole.matchFulfilled,
      (state, { payload }) => {
        state.user = {
          ...state.user,
          business_role: payload?.payload?.business_role,
        };
      },
    );

    builder.addMatcher(
      settingsService.endpoints.moduleShopify.matchFulfilled,
      (state, { payload }) => {
          if (payload?.payload?.access_token) {
            state.access_token = payload?.payload?.access_token;
          }
      },
    );
  },
});

export const { setToken, setShowDrawer, setBaseUrl, setIsLoadingCreate } =
  authSlice.actions;

export const selectShowDrawer = (state) => state.auth.showDrawer;
export const selectBaseUrl = (state) => state.auth.baseUrl;
export const selectIsLoadingCreate = (state) => state.auth.isLoadingCreate;

export default authSlice.reducer;
