import React from "react";
import {
  FacebookFilled,
  YoutubeFilled,
} from "@ant-design/icons";

import { ICONS_LINK } from "../../../constants/footer";

import { Container, Link } from "./styles";

const SocialLink = () => (
  <Container>
    <Link background="#6a9ce1" href={ICONS_LINK.FACEBOOK_LINK} target="_blank">
      <FacebookFilled />
    </Link>

    <Link background="#e9594d" href={ICONS_LINK.YOUTUBE_LINK} target="_blank">
      <YoutubeFilled />
    </Link>
  </Container>
);

export default SocialLink;
