import React from "react";

import HostApiForm from "../components/HostApiForm";
import ThemeSetting from "../components/ThemeSetting";
import MirrorForm from "../components/MirrorForm";

export const createEditAcordionItems = (formState, onClickAction) => [
  {
    key: "1",
    label: "Change host Api",
    children: (
      <HostApiForm formState={formState} onClickAction={onClickAction} />
    ),
  },
  {
    key: "2",
    label: "Change theme",
    children: <ThemeSetting onClickAction={onClickAction} />,
  },
  {
    key: "3",
    label: "Change Mirror Host",
    children: (
      <MirrorForm formState={formState} onClickAction={onClickAction} />
    ),
  },
];
