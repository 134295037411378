import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.pallette.silver.secondary};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0 4px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.pallette.silver.primary};
`;

export const TitleBold = styled(Title)`
  font-weight: 500;
  color: ${({ theme }) => theme.pallette.blue.primary};
`;
