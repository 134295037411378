import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { AUTH_PATH } from "../../router/path/auth";
import { useAuth } from "../../hooks/useAuth";
import { selectSettings } from "../../store/settings/settingsSlice";
import ModalRole from "../../components/ModalRole";

const AuthProviders = () => {
  const { options } = useSelector(selectSettings);
  const { user } = useAuth();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (
      !user?.business_role &&
      options?.business_role?.required_in_popup
    ) {
      setOpenModal(true);
    }
  }, []);

  return user?.id ? (
    <>
      {openModal && <ModalRole open={openModal} close={setOpenModal} />}
      <Outlet />
    </>
  ) : (
    <Navigate to={AUTH_PATH.LOGIN} replace />
  );
};

export default AuthProviders;
