export const ADMIN_PATH = {
  CONFIGURATION: "configuration",
  ADMIN_LOGS: "admin/logs",
  ADMIN_MIGRATION_SURVEYS: "admin/migrations-surveys",
  MI_REPORTS_WIZARD_LOGS: "admin/reports/wizardlogs",
  MI_PRICE_RULES: "admin/price-rules",
  ADMIN_MIGRATION: "admin/migrations",
  ADMIN_INSTANCES: "admin/instances",
  PACKAGES: "admin/price-rules/packages",
  SUPPORTED_ENTITIES: "admin/supported-entities",
  TRANSLATIONS: "admin/configuration/translations",
  ACCOUNTS: "admin/accounts",
  COUPONS: "admin/coupons",
  ESTIMATES: "admin/accounts/estimates",
  BALANCE_HISTORY: "admin/accounts/accounts-balance-history",
  GROUPS: "admin/accounts_groups",
  ESTIMATOR_PRICE_MULTIPLIERS: "estimation/price-multipliers",
  CURRENCIES: "admin/configuration/currencies",
  MIGRATION_INSURANCES: "admin/configuration/migration-insurances",
  CARTS_SEO_TEXTS: "admin/configuration/cart-pair-seo-texts",
  CARTS_UPGRADE_SEO_TEXTS: "admin/configuration/cart-upgrade-seo-texts",
  REGISTRATION_ADD_FUNDS: "admin/configuration/registration-add-funds",
  MIGRATION_BLOCK: "admin/configuration/migration-block-config",
  ENTITIES_PACKS: "admin/configuration/entities-packs",
  MIRRORS: "admin/configuration/mirrors",
  REPORTS_COUPONS: "admin/reports/coupons",
  REPORTS_LOGS: "admin/reports/logs",
  DEBUG_STATISTICS: "admin/debug-statistics",
  REPORTS_WIZARD_LOGS: "admin/reports/wizardlogs",
  REPORTS_WIZARD_LOGS_STATISTICS: "admin/reports/wizardlogs-statistics",
  REPORTS_ERRORS_STATISTICS: "admin/reports/error-statistics",
  WIZARD_STEPS_FUNNEL: "admin/reports/wizard-steps-funnel",
  PROXIES_STATISTICS: "admin/reports/proxies-statistics",
  PROTRACTOR_STATISTICS: "admin/reports/protractor-statistics",
  UNSUBSCRIBERS: "admin/reports/unsubscribers",
  MIGRATIONS: "migrations",
  MIGRATION_BY_MIGRATION_ID: "migrations/index/mID/:id",
  ADMIN_MIGRATION_BY_MIGRATION_ID: "admin/migrations/index/:mId",
  MIGRATION_BY_ACCOUNT_ID: "migrations/index/aID/:id",
  MIGRATIONS_BY_RATES: "admin/reports/migrations-rates",
  WIZARD_STATS: "admin/reports/wizard-stats",
  RE_MIGRATION_STATS: "admin/reports/remigration-statistics",
  TICKETS_SURVEY: "admin/reports/tickets-survey",
  REFERRAL_URL_STATISTICS: "admin/reports/referral-url-statistics",
  QUEUE: "admin/accounts-messages",
  OPTIONS_INSURACES_RECENT_DATA: "admin/reports/migrations-services",

  TEST: "test-page",
  ADMIN_AEROSQL_BY_MIGRATION_ID: "/admin/aerosql/:id",
  ADMIN_AEROSQL: "admin/aerosql",
  ADMIN_SMART_MAPPING: "admin/smart-mapping",
  ADMIN_CART_STORES: "admin/cart-preview/stores",
  ADMIN_REPORTS: "admin/reports",
  ACCOUNT_BY_ACCOUNT_ID: "admin/accounts/index/aId/:id",
  ACCOUNT_BY_ID: "admin/accounts/index/aId",
  ADMIN_MIGRATION_BY_ACCOUNT_ID: "admin/migrations/index/aId/:id",
  ADMIN_MIGRATION_BY_ID: "admin/migrations/index/aId",
  ADMIN_REPORTS_SERVICES: "admin/reports/services-report",
  ROLES: "admin/accounts-roles",
  PERMISSIONS: "admin/permissions",
  MIGRATIONS_STARTER: "admin/migrations-starter",
  ADMIN_DASHBOARD: "admin/dashboard",

  ADMIN_CART_PREVIEW_STORES: "admin/cart-preview/stores",
  ADMIN_CART_PREVIEW_PREPARED_STORES: "admin/cart-preview/prepared-stores",
  ADMIN_CART_PREVIEW_CONFIGURATION: "admin/cart-preview/configuration",
  ADMIN_CART_PREVIEW_LOGS: "admin/cart-preview/logs",
  ADMIN_CART_PREVIEW_REPORT: "admin/cart-preview/report",
  ADMIN_MIGRATION_ISSUES: "admin/migrations-issues",

  SURVEY_TICKET: "admin/survey-ticket",
  FEEDBACK: "admin/feedback",
  FEEDBACK_TABLE: "admin/reports/user-feedbacks",

  ACCESS: "/access-form",
  ACCESS_SUCCESS: "/access-form/success",
};
