export const MIGRATIONS_ROOT_PATH = "/migrations";

export const MIGRATIONS_PATH = {
  MY_MIGRATIONS_LIST: "migrations/list",
  MY_MIGRATION_DETAILS: "migrations/details/index/id",
  MIGRATIONS: "migrations",
  MIGRATION_BY_MIGRATION_ID: "migrations/index/mID/:id",
  MIGRATION_BY_ACCOUNT_ID: "migrations/index/aID/:id",
  MIGRATION_LOG_BY_ID: "migrations/list/log/id/:id",
  RESTART_MIGRATION: "migrations/list/restart-migration/id",
};
