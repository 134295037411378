import styled from "styled-components";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  && {
    &.custom {
      width: 100%;
      max-width: 264px;
      font-style: normal;
      font-weight: 500;
      height: 50px;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: ${({ theme }) => theme.pallette.blue.primary};
      background: ${({ theme }) => theme.pallette.green.light};
      border-radius: 100px;
      border: none;

      &:hover {
        background: ${({ theme }) => theme.pallette.blue.main};
        color: ${({ theme }) => theme.pallette.white.main};
      }
    }
  }

  @media (max-width: 767px) {
    && {
      &.custom {
        max-width: 100%;
      }
    }
  }
`;
