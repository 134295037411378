import { BaseQueryParams } from "../baseQuery";

export const supportedEntitiesService = BaseQueryParams(
  "supportedEntitiesService",
  ["SUPPORTED_ENTITIES"],
).injectEndpoints({
  endpoints: (builder) => ({
    getEntities: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/migrations/supported/entities${
          page ? `?page=${page}` : ""
        }&per_page=${per_page ? `${per_page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["SUPPORTED_ENTITIES"],
    }),

    deleteEntity: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/supported/entities/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["SUPPORTED_ENTITIES"],
    }),

    createEntity: builder.mutation({
      query: (body) => ({
        url: "/admin/migrations/supported/entities",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["SUPPORTED_ENTITIES"],
    }),

    editEntity: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/supported/entities/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["SUPPORTED_ENTITIES"],
    }),

    getEntitiesAttributes: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/migrations/supported/entities/attributes${
          page ? `?page=${page}` : ""
        }&per_page=${per_page ? `${per_page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["SUPPORTED_ENTITIES_ATTRIBUTES"],
    }),

    deleteEntityAttribute: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/supported/entities/attributes/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["SUPPORTED_ENTITIES_ATTRIBUTES"],
    }),

    createEntityAttribute: builder.mutation({
      query: (body) => ({
        url: "/admin/migrations/supported/entities/attribute",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["SUPPORTED_ENTITIES_ATTRIBUTES"],
    }),

    editEntityAttribute: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/supported/entities/attributes/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["SUPPORTED_ENTITIES_ATTRIBUTES"],
    }),
  }),
});

export const {
  useGetEntitiesQuery,
  useDeleteEntityMutation,
  useCreateEntityMutation,
  useEditEntityMutation,
  useGetEntitiesAttributesQuery,
  useDeleteEntityAttributeMutation,
  useCreateEntityAttributeMutation,
  useEditEntityAttributeMutation,
} = supportedEntitiesService;
