import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 300px;
  flex-direction: column;
  padding-top: ${({ $modalType }) => ($modalType ? "0" : "32px")};
  background: ${({ theme }) => theme.pallette.white.main};
  align-items: center;
  box-shadow: 4px 4px 16px -10px rgba(24, 35, 83, 0.1);
  border-radius: 24px;
  margin-top: ${({ $modalType }) => ($modalType ? "0" : "32px")};
  margin-bottom: ${({ $modalType }) => ($modalType ? "0" : "100px")};
  display: flex;
  gap: ${({ $modalType }) => ($modalType ? "0" : "40px")};

  && > svg {
    margin-bottom: 8px;
  }

  && > div:first-child > div {
    max-width: 650px;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.pallette.blue.primary};
`;
