import React, { memo } from "react";

import { StyledLink, Container, Text } from "./styles";

const DropdownLink = ({ to, title, icon, border }) => (
  <StyledLink to={to} border={border ? 1 : 0}>
    <Container>
      {icon} <Text>{title}</Text>
    </Container>
  </StyledLink>
);

export default memo(DropdownLink);
