import React from "react";
import ModalInput from "../../../../components/ModalMacros/components/ModalInput";
import TableHeader from "../../../../components/TableMacros/components/TableHeader";
import { actionsForm, inputRules } from "../../constants";
import { Container } from "./styles";

const MirrorForm = ({ formState, onClickAction }) => {
  const onButton = (values, type) => {
    if (type === "edit") {
      localStorage.setItem("mirror_host", values?.mirror_host);
    }

    if (type === "delete") {
      localStorage.removeItem("mirror_host");
    }

    onClickAction();
  };

  return (
    <Container>
      <ModalInput
        label="Mirror Host"
        control={formState.control}
        name="mirror_host"
        error={formState?.formState?.errors?.mirror_host?.message}
        rules={inputRules.mirror_host}
      />

      <TableHeader
        actions={actionsForm}
        redirectByType={formState.handleSubmit(onButton)}
      />
    </Container>
  );
};
export default MirrorForm;
