import styled from "styled-components";
import Modal from "../UI/Modal";

export const ModalMacros = styled(Modal)`
  .ant-modal-body {
    font-family: "Poppins", sans-serif;
    padding: 0;
    text-align: center;
  }
  .ant-modal-footer {
    margin-top: 32px;
  }
`;

export const Logo = styled.div`
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

export const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.pallette.dark.main};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const SubTitle = styled.p`
  text-align: ${({ $center }) => ($center ? "center" : "left")};
  color: ${({ theme }) => theme.pallette.silver.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Carts = styled.div`
  margin: 8px 0 32px;
  max-height: 294px;
  overflow: auto;
`;

export const CartList = styled.div`
  div,
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 8px 12px 8px 6px;
    color: ${({ theme }) => theme.pallette.blue.primary};
    border-bottom: 1px solid ${({ theme }) => theme.pallette.silver.secondary};
    border-radius: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    img {
      width: 32px;
      height: 32px;
      margin-right: 6px;
    }
  }
`;
