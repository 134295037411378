import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox } from "antd";

import { Container, Wrapper, Error } from "./styles";

const ModalCheckBoxGroup = ({ options, name, control, error, ...rest }) => (
  <Container>
    <Wrapper>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox.Group options={options} {...field} {...rest} />
        )}
      />

      {error && <Error>{error}</Error>}
    </Wrapper>
  </Container>
);

export default ModalCheckBoxGroup;
