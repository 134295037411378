import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { MIGRATIONS_PATH } from "../../router/path/migrations";
import { useFormTo } from "../../hooks/useFormTo";

function Fallback({ resetErrorBoundary }) {
  const navigate = useNavigate();
  const { prefix } = useFormTo();

  useEffect(() => {
    window.location.href = `/${prefix && `${prefix}/`}${
      MIGRATIONS_PATH.MY_MIGRATIONS_LIST
    }`;
    resetErrorBoundary();
  }, [navigate, resetErrorBoundary]);

  return null;
}

const ErrorProvider = ({ children }) => (
  <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
);

export default ErrorProvider;
