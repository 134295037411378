import styled from "styled-components";
import { Dropdown } from "antd";

export const StyledDiv = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledDropdown = styled(Dropdown)`
  z-index: 100;
  overflow: none;
  font-weight: 300;

  .ant-checkbox-group {
    max-height: fit-content;
    flex-direction: column;
    flex-wrap: nowrap;
    text-transform: none;
    overflow: auto;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    border: none;
    width: 230px;
    padding: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.pallette.white.main};
    box-shadow:
      0px 4px 6px -2px rgba(24, 35, 83, 0.05),
      0px 10px 15px -3px rgba(24, 35, 83, 0.1),
      0px 0px 0px 1px rgba(24, 35, 83, 0.05);
    gap: 16px;
  }
`;
