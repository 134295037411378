import { BaseQueryParams } from "../baseQuery";

export const translationsService = BaseQueryParams("translationsService", [
  "Languages",
  "Translations",
]).injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/translations/languages${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["Languages"],
    }),

    createLanguage: builder.mutation({
      query: (body) => ({
        url: "/admin/translations/languages",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["Languages"],
    }),

    updateLanguage: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/translations/languages/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["Languages"],
    }),

    deleteLanguage: builder.mutation({
      query: (id) => ({
        url: `/admin/translations/languages/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["Languages"],
    }),

    getTranslations: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/translations/tree${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["Translations"],
    }),

    createTranslation: builder.mutation({
      query: (body) => ({
        url: "/admin/translations/dictionary",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["Translations"],
    }),

    updateTranslation: builder.mutation({
      query: ({ body }) => ({
        url: `/admin/translations/dictionary`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["Translations"],
    }),

    deleteTranslation: builder.mutation({
      query: (id) => ({
        url: `/admin/translations/dictionary/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["Translations"],
    }),
  }),
});

export const {
  useGetLanguagesQuery,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
  useDeleteLanguageMutation,
  useGetTranslationsQuery,
  useCreateTranslationMutation,
  useUpdateTranslationMutation,
  useDeleteTranslationMutation,
} = translationsService;
