import React from "react";
import { useDispatch } from "react-redux";
import { setBaseUrl } from "../../../../store/authentication/authSlice";
import ModalInput from "../../../../components/ModalMacros/components/ModalInput";
import TableHeader from "../../../../components/TableMacros/components/TableHeader";
import { actionsForm, inputRules } from "../../constants";
import { Container } from "./styles";

const HostApiForm = ({ formState, onClickAction }) => {
  const dispatch = useDispatch();

  const onButton = (values, type) => {
    if (type === "edit") {
      localStorage.setItem("host_api", values?.host_api);
      dispatch(setBaseUrl(values?.host_api));
    }

    if (type === "delete") {
      localStorage.removeItem("host_api");
      dispatch(setBaseUrl(""));
    }

    onClickAction();
  };

  return (
    <Container>
      <ModalInput
        label="Host Api"
        control={formState.control}
        name="host_api"
        error={formState?.formState?.errors?.host_api?.message}
        rules={inputRules.host_api}
      />

      <TableHeader
        actions={actionsForm}
        redirectByType={formState.handleSubmit(onButton)}
      />
    </Container>
  );
};
export default HostApiForm;
