/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import ModalMacros from "../../../../components/ModalMacros/components/ModalMacros";
import TableAccordion from "../../../../components/TableMacros/components/TableAccordion";
import { createEditAcordionItems } from "../../utils";
import { Container } from "./styles";

const ModalConfig = ({ onCancel, open, formState, onClickAction }) => (
  <ModalMacros
    title="Configuration"
    onCancel={onCancel}
    open={open}
    footer={false}
    width={700}
  >
    <Container>
      <TableAccordion
        items={createEditAcordionItems(formState, onClickAction)}
      />
    </Container>
  </ModalMacros>
);

export default ModalConfig;
