import { BaseQueryParams } from "../baseQuery";

export const defaultService = BaseQueryParams(
  "defaultService",
  [],
).injectEndpoints({
  endpoints: (builder) => ({
    sendDefaultRequest: builder.mutation({
      query: ({url, method, body}) => ({
        url: url.replace(/^(\/v\d+)/, ''),
        method,
        body,
      }),
    }),
  }),
});

export const { useSendDefaultRequestMutation } = defaultService;
