/* eslint-disable react/no-array-index-key */
import React from "react";
import { useDispatch } from "react-redux";

import { CustomLink } from "./styles";
import { WIZARD_STEP_PATH } from "../../../router/path/wizard";
import { useWizardSteperRouter } from "../../../hooks/useWizardSteperRouter";
import {
  setNewTargetStore,
  setNewSourceStore,
  setNotification,
} from "../../../store/wizardCreate/wizardCreateSlice";
import { useCheckTypeConnectMutation } from "../../../store/wizardCreate/wizardCreateService";

const SetNewStore = ({ error, variables, onClose }) => {
  const dispatch = useDispatch();
  const { redirectToNewWizardStep } = useWizardSteperRouter();
  const placeholder = `{{${variables?.link?.type}}}`;
  const messageParts = error?.split(placeholder) || [];

  const [checkTypeConnect] = useCheckTypeConnectMutation();
  const handleCheckTypeConnect = async (value, cartType) => {
    try {
      await checkTypeConnect({
        source_cart_id: value,
        type: cartType,
      }).unwrap();
    } catch (error) {
      dispatch(setNotification(error));
    }
  };

  const changeStore = () => {
    if (variables["{{storeType}}"] === "target") {
      redirectToNewWizardStep(WIZARD_STEP_PATH.SELECT_TARGET);
      dispatch(setNewTargetStore(variables["{{cartId}}"]));
    } else if (variables["{{storeType}}"] === "source") {
      dispatch(setNewSourceStore(variables["{{cartId}}"]));
      redirectToNewWizardStep(WIZARD_STEP_PATH.SELECT_SOURCE);

      handleCheckTypeConnect(
        variables["{{cartId}}"],
        variables["{{storeType}}"],
      );
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {messageParts.map((part, index) => (
        <span key={index}>
          <span dangerouslySetInnerHTML={{ __html: part }} />
          {index < messageParts.length - 1 && (
            <CustomLink onClick={changeStore}>
              {variables["{{cartId}}"]}
            </CustomLink>
          )}
        </span>
      ))}
    </>
  );
};

export default SetNewStore;
