import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  transition: all 0.5s ease;

  svg {
    width: 35px;
    height: 35px;
    color: ${({ background }) => background};
  }

  &:hover {
    color: ${({ theme }) => theme.pallette.white.main};
    opacity: 0.6;
  }
`;
