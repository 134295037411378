import { BaseQueryParams } from "../baseQuery";

export const mirrorsService = BaseQueryParams("mirrorsService", [
  "Mirrors",
]).injectEndpoints({
  endpoints: (builder) => ({
    getMirrors: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/configurations/mirrors${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["Mirrors"],
    }),

    deleteMirrors: builder.mutation({
      query: (id) => ({
        url: `/admin/configurations/mirrors/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["Mirrors"],
    }),

    createMirrors: builder.mutation({
      query: (body) => ({
        url: "/admin/configurations/mirrors",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["Mirrors"],
    }),

    updateMirrors: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/configurations/mirrors/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["Mirrors"],
    }),
  }),
});

export const {
  useGetMirrorsQuery,
  useDeleteMirrorsMutation,
  useCreateMirrorsMutation,
  useUpdateMirrorsMutation,
} = mirrorsService;
