import React, { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useMigrationSession } from "../../hooks/useMigrationSession";
import { useLoyaltyPermission } from "../../hooks/useLoyaltyPermission";

import HeaderUserAvatar from "./HeaderUserAvatar";
import HeaderMigrationInfo from "./HeaderMigrationInfo";
import HeaderPartnerLevel from "./HeaderPartnerLevel";

import { Container } from "./styles";

const HeaderUserMenu = () => {
  const migration = useMigrationSession();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const isLoyalty = useLoyaltyPermission();

  const isWizardRoute = pathname?.includes("/wizard/");
  const cartMigration = `${migration?.source_store?.cart || "Magento"} / ${
    migration?.target_store?.cart || "Shopify"
  }`;

  return (
    <Container>
      {isWizardRoute && (
        <>
          <HeaderMigrationInfo title="Cart:" subTitle={cartMigration} />

          <HeaderMigrationInfo
            title="ID migration:"
            subTitle={migration?.id || "-"}
          />
        </>
      )}

      {isLoyalty && <HeaderPartnerLevel level={user?.loyalty_level} />}

      <HeaderUserAvatar />
    </Container>
  );
};

export default memo(HeaderUserMenu);
