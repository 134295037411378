import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: transparent;
`;

export const Wrapper = styled.div`
  display: flex;
  visibility: ${({ active }) => (active === 1 ? "visible" : "hidden")};
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
