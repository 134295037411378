import { BaseQueryParams } from "../baseQuery"

export const seoTextsService = BaseQueryParams("seoTextsService", ["CART_PAIRS"]).injectEndpoints({
  endpoints: (builder) => ({
    getCartPairs: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/marketing/cart-pair-seo-text${page ? `?page=${page}`: ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["CART_PAIRS"],
    }),

    deleteCartPair: builder.mutation({
      query: (id) => ({
        url: `/admin/marketing/cart-pair-seo-text/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ['CART_PAIRS'],
    }),

    createCartPair: builder.mutation({
      query: (body) => ({
        url: "/admin/marketing/cart-pair-seo-text",
        method: "POST",
        body
      }),

      invalidatesTags: () => ['CART_PAIRS'],
    }),

    editCartPair: builder.mutation({
      query: ({id, body}) => ({
        url: `/admin/marketing/cart-pair-seo-text/${id}`,
        method: "PUT",
        body
      }),

      invalidatesTags: () => ['CART_PAIRS'],
    }),
  })
})

export const {
  useGetCartPairsQuery,
  useDeleteCartPairMutation,
  useCreateCartPairMutation,
  useEditCartPairMutation,
} = seoTextsService;
