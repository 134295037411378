import React from "react";

import AuthInputByType from "../AuthInputByType";
import { Container } from "./styles";

const AuthFormInputs = ({ inputs, control, errors }) => (
  <Container>
    {inputs.map((input) => (
      <AuthInputByType
        key={input.name}
        type={input.name}
        control={control}
        error={errors?.[input.name]?.message}
        {...input}
      />
    ))}
  </Container>
);

export default AuthFormInputs;
