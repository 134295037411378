import React, { useState, useRef, useEffect, memo } from "react";
import OauthPopup from "react-oauth-popup";

import { ReactComponent as SocialFIcon } from "../../../../assets/images/SocialFIcon.svg";
import { ReactComponent as SocialGIcon } from "../../../../assets/images/SocialGIcon.svg";
import {
  useSocialLoginMutation,
  useSocialLoginCallbackMutation,
} from "../../../../store/authentication/authService";

import { Container, SocialButton } from "./styles";

const AuthSocialForm = ({ title }) => {
  const [url, setUrl] = useState("");
  const [social, setSocial] = useState();
  const refBtnFaceBook = useRef(null);

  const [login, { isLoading }] = useSocialLoginMutation();
  const [loginCallback] = useSocialLoginCallbackMutation();

  const onClose = () => {
    setUrl("");
    setSocial("");
  };

  const sendCode = async (code) => {
    try {
      await loginCallback({
        social,
        code,
      }).unwrap();

      setUrl("");
      setSocial("");
    } catch (error) {
      setUrl("");
      setSocial("");
    }
  };

  const loginFacebook = async () => {
    try {
      setSocial("facebook");
      const res = await login({ social: "facebook" }).unwrap();

      if (res?.payload?.redirect_url) {
        setUrl(`${res?.payload?.redirect_url}&approval_prompt=force`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loginGoogle = async () => {
    try {
      setSocial("google");
      const res = await login({ social: "google" }).unwrap();

      if (res?.payload?.redirect_url) {
        setUrl(`${res?.payload?.redirect_url}&approval_prompt=force`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (url) {
      refBtnFaceBook?.current?.click();
    }
  }, [url]);

  return (
    <Container>
      <OauthPopup url={url} onCode={sendCode} onClose={onClose}>
        <div ref={refBtnFaceBook} />
      </OauthPopup>

      {/* <SocialButton */}
      {/*  onClick={loginFacebook} */}
      {/*  loading={isLoading && social === "facebook"} */}
      {/*  icon={<SocialFIcon />} */}
      {/*  color="#1877F2" */}
      {/* > */}
      {/*  Sign in with Facebook */}
      {/* </SocialButton> */}

      <SocialButton
        onClick={loginGoogle}
        loading={isLoading && social === "google"}
        icon={<SocialGIcon />}
        color="#E22C2A"
      >
        {title} with Google
      </SocialButton>
    </Container>
  );
};

export default memo(AuthSocialForm);
