import React, { useCallback } from "react";

import ShowColsDropdown from "../../../UI/ShowColsDropdown";
import HeaderAction from "../HeaderAction";

import { standartHeaderActions } from "../../utils/actions-button";

import { Container, Wrapper, MainWrapper } from "./styles";

const TableHeader = ({
  actions,
  redirectByType,
  control,
  colsOptions,
  csvLoading,
  customHeader,
  groupOptions,
  headerCol,
  topHeader,
}) => {
  const buttonsByActions = useCallback(() => {
    if (actions) {
      return actions();
    }

    return standartHeaderActions();
  }, []);

  return (
    <Container>
      {topHeader}

      <Wrapper longheader={headerCol}>
        {customHeader}

        <MainWrapper>
          {buttonsByActions()?.map((item) => {
            if (item?.type === "groupBy") {
              return (
                <ShowColsDropdown
                  key={item?.type}
                  control={control}
                  options={groupOptions}
                  name="active_groups"
                >
                  <HeaderAction
                    key={item?.type}
                    type={item?.type}
                    title={item?.title}
                    redirectByType={redirectByType}
                    arrow
                  />
                </ShowColsDropdown>
              );
            }

            if (item?.type === "filters") {
              return (
                <ShowColsDropdown
                  key={item?.type}
                  control={control}
                  options={colsOptions}
                  name="active_cols"
                >
                  <HeaderAction
                    key={item?.type}
                    type={item?.type}
                    title={item?.title}
                    redirectByType={redirectByType}
                    arrow
                  />
                </ShowColsDropdown>
              );
            }

            return (
              <HeaderAction
                key={item?.type}
                type={item?.type}
                title={item?.title}
                redirectByType={redirectByType}
                csvLoading={csvLoading}
              />
            );
          })}
        </MainWrapper>
      </Wrapper>
    </Container>
  );
};

export default TableHeader;
