import styled from "styled-components";
import { Input } from "antd";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.pallette.blue.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const StyledInput = styled(Input)`
  && {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.pallette.silver.secondary};

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .ant-input {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.pallette.blue.primary};
  }

  .ant-input-affix-wrapper > input.ant-input {
    font-size: 16px;
  }
`;

export const Error = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.red.error};
`;
