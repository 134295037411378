import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    max-height: 200px;
    flex-direction: row;
    columns: 2 auto;
    width: 100%;
    overflow-y: scroll;
    padding: 5px 0;
  }

  .ant-checkbox-group > * {
    flex: 0 0 50%;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.pallette.silver.main};
    border-color: ${({ theme }) => theme.pallette.blue.link};
    border-radius: 5px;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-radius: 5px;
  }

  .ant-checkbox-wrapper .ant-checkbox-checked::after {
    border-radius: 5px;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    border-color: ${({ theme }) => theme.pallette.blue.link};
    border-radius: 5px;
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white !important;
        border-color: ${({ theme }) => theme.pallette.blue.link} !important;
      }
    }
  }
`;

export const Error = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.red.error};
  padding-top: 10px;
`;
