import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useDrawer } from "../../../hooks/useDrawer";
import { setShowDrawer } from "../../../store/authentication/authSlice";
import { ReactComponent as PhoneIcon } from "../../../assets/images/PhoneIcon.svg";
import { ReactComponent as MenuHumbOutlined } from "../../../assets/images/menuHumb.svg";
import Drawer from "../Drawer";

import {
  Container,
  MenuHamb,
  WrapperText,
  Title,
  SubTitle,
  StyledLink,
} from "./styles";

const ResponseMenu = ({ menu }) => {
  const dispatch = useDispatch();
  const showDrawer = useDrawer();

  const onShowDrawer = () => dispatch(setShowDrawer(true));
  const onClose = () => dispatch(setShowDrawer(false));

  return (
    <Container>
      <WrapperText>
        <Title>
          24/7 | <PhoneIcon />
          <StyledLink href="tel:1-800-224-1462">1-800-224-1462</StyledLink>
        </Title>

        <SubTitle>US & Canada Toll Free</SubTitle>
      </WrapperText>

      <MenuHamb onClick={onShowDrawer}>
        <MenuHumbOutlined />
      </MenuHamb>

      <MenuHamb>
        <Drawer open={showDrawer} onClose={onClose} menu={menu} />
      </MenuHamb>
    </Container>
  );
};

export default ResponseMenu;
