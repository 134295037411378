import { BaseQueryParams } from "../baseQuery";

export const migrationMultipliersService = BaseQueryParams(
  "migrationMultipliersService",
  ["MIGRATION_MULTIPLIERS"],
).injectEndpoints({
  endpoints: (builder) => ({
    getMultipliers: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/estimations/multipliers${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
      providesTags: () => ["MIGRATION_MULTIPLIERS"],
    }),

    deleteMultiplier: builder.mutation({
      query: (id) => ({
        url: `/admin/estimations/multipliers/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["MIGRATION_MULTIPLIERS"],
    }),

    createMultiplier: builder.mutation({
      query: (body) => ({
        url: "/admin/estimations/multipliers",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_MULTIPLIERS"],
    }),

    editMultiplier: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/estimations/multipliers/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_MULTIPLIERS"],
    }),
  }),
});

export const {
  useGetMultipliersQuery,
  useDeleteMultiplierMutation,
  useEditMultiplierMutation,
  useCreateMultiplierMutation,
} = migrationMultipliersService;
