import { BaseQueryParams } from "../baseQuery"

export const downloadCSVService = BaseQueryParams("downloadCSVService").injectEndpoints({
  endpoints: (builder) => ({
    getCSVLink: builder.mutation({
      query: ({ route, filters }) => ({
        url: `${route}/csv?config=1`,
        method: "GET",
        params: filters,
      }),
    }),
  })
})

export const {
  useGetCSVLinkMutation,
} = downloadCSVService
