import { BaseQueryParams } from "../baseQuery";

export const adminCouponsService = BaseQueryParams("adminCouponsService", [
  "Coupons",
]).injectEndpoints({
  endpoints: (builder) => ({
    getAdminCoupons: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/migrations/coupons${page ? `?page=${page}` : ""}${
          per_page ? `&per_page=${per_page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["Coupons"],
    }),

    deleteAdminCoupon: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/coupons/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["Coupons"],
    }),

    createAdminCoupon: builder.mutation({
      query: (body) => ({
        url: "/admin/migrations/coupons",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["Coupons"],
    }),

    updateAdminCoupon: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/coupons/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["Coupons"],
    }),

    getUserEmails: builder.query({
      query: ({ email }) => ({
        url: `/migrations/wizard/get-accounts${
          email ? `?search=${encodeURIComponent(email)}` : ""
        }`,
        method: "GET",
      }),
    }),

    getAvailCarts: builder.query({
      query: () => ({
        url: "/admin/migrations/coupons/avail-carts",
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetAdminCouponsQuery,
  useDeleteAdminCouponMutation,
  useCreateAdminCouponMutation,
  useUpdateAdminCouponMutation,
  useGetUserEmailsQuery,
  useGetAvailCartsQuery,
} = adminCouponsService;
