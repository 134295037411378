import { useCallback } from "react";
import TagManager from "react-gtm-module";

import { useAuth } from "./useAuth";
import { useMigrationSession } from "./useMigrationSession";

export const useGtmEvent = () => {
  const { user } = useAuth();
  const migration = useMigrationSession();

  const sendGtmEvent = useCallback(
    (eventName) => {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          user_id: user?.id,
          migration_id: migration?.id || "",
        },
      });

      // console.log(eventName, {
      //   event: eventName,
      //   user_id: user?.id,
      //   migration_id: migration?.id,
      // });
    },
    [user],
  );

  return { sendGtmEvent };
};
