import React, { memo } from "react";

import QuizType from "../QuizType";

import { Container } from "./styles";

const RegistrationQuizByType = ({
  type,
  formState,
  data,
  modalType,
  errors,
}) => (
  <Container>
    <QuizType
      formState={formState}
      data={data[type]}
      modalType={modalType}
      type={type}
      error={errors}
    />
  </Container>
);

export default memo(RegistrationQuizByType);
