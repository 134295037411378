import React from "react";
import { Controller } from "react-hook-form";

import { ReactComponent as IconInputError } from "../../../../assets/images/IconInputError.svg";
import { Container, Label, StyledInput, Wrapper, Error } from "./styles";

const ModalInput = ({
  label,
  placeholder,
  type,
  control,
  name,
  disabled,
  error,
  rules,
  ...rest
}) => (
  <Container>
    <Label>{label}</Label>

    <Wrapper>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <StyledInput
            {...field}
            {...rest}
            placeholder={placeholder}
            type={type}
            suffix={error ? <IconInputError /> : <span />}
            status={error ? "error" : ""}
            disabled={disabled}
          />
        )}
      />

      {error && <Error>{error}</Error>}
    </Wrapper>
  </Container>
);

export default ModalInput;
