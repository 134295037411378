import { BaseQueryParams } from "../baseQuery"

export const migrationsStarterService = BaseQueryParams("migrationStarter", ["MIGRATIONS_STARTER"]).injectEndpoints({
  endpoints: (builder) => ({
    getMigrationsStarter: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations/starters${page ? `?page=${page}`: ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["MIGRATIONS_STARTER"],
    }),

    deleteMigrationStarter: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/starters/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ['MIGRATIONS_STARTER'],
    }),
  })
})

export const {
  useGetMigrationsStarterQuery,
  useDeleteMigrationStarterMutation,
} = migrationsStarterService;
