import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 15px;
  padding-bottom: 50px;

  && .ant-collapse-header {
    flex-direction: row;
    padding-left: 0;
  }

  & > div:not(:last-child) {
    padding: 0;
  }

  && .ant-collapse {
    background-color: ${({ theme }) => theme.pallette.white.main};

    .ant-collapse-content-box {
      background-color: ${({ theme }) => theme.pallette.white.main};
    }
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.primary};
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.pallette.silver.primary};
  overflow: hidden;
`;

export const Wrapper = styled.div`
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.pallette.silver.secondary}` : "none"};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

export const LogoutButton = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.pallette.white.main};
  display: flex;
  align-items: center;
  padding: 16px 24px;
  cursor: pointer;
  transition: all 0.5s ease;
  border-top: 1px solid ${({ theme }) => theme.pallette.silver.secondary};

  position: absolute;
  left: 0;
  bottom: 0;

  &&:hover {
    background-color: ${({ theme }) => theme.pallette.silver.secondary};
  }
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.primary}!important;
  margin-left: 15px;
`;

export const StyledButton = styled.button`
  width: 264px;
  height: 50px;
  margin: 0 auto;
  background: ${({ theme }) => theme.pallette.white.main};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.pallette.blue.primary};
  border: 1px solid ${({ theme }) => theme.pallette.green.light};

  &.ant-btn-default {
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${({ theme }) => theme.pallette.green.secondary};
      border: 1px solid ${({ theme }) => theme.pallette.green.secondary};
      color: ${({ theme }) => theme.pallette.white.main};
    }

    &:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${({ theme }) => theme.pallette.green.light};
      border: none;
      color: ${({ theme }) => theme.pallette.blue.primary};
    }
  }
`;
