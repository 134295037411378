import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectSettings } from "../store/settings/settingsSlice";

export const usePublicConfig = () => {
  const { env } = useSelector(selectSettings);

  return useMemo(() => ({ env }), [env]);
};
