import React from "react";
import ModalButton from "../ModalButton";
import { Container } from "./styles";

const ModalFooter = ({ onClose, closeTitle, onSubmit, submitTitle }) => (
  <Container>
    {onClose && (
      <ModalButton type="default" onClick={onClose} reset="true">
        {closeTitle || "Close"}
      </ModalButton>
    )}

    {onSubmit && (
      <ModalButton type="default" onClick={onSubmit}>
        {submitTitle || "Ok"}
      </ModalButton>
    )}
  </Container>
);

export default ModalFooter;
