import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div:nth-child(2) {
    margin: 40px 0 24px 0;
  }
`;
