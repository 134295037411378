import React, { memo } from "react";

import AuthInputPassword from "../AuthInputPassword";
import AuthInput from "../AuthInput";

const AuthInputByType = ({ type, ...rest }) => {
  if (type === "password") {
    return <AuthInputPassword {...rest} />;
  }

  return <AuthInput {...rest} />;
};

export default memo(AuthInputByType);
