import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.pallette.blue.top};

  @media (max-width: 767px) {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
export const Wrapper = styled.div`
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
  max-width: 1200px;
  padding: 8px;
  color: ${({ theme }) => theme.pallette.blue.primary};
`;
export const StyledLink = styled.a`
  margin-left: 8px;
  font-weight: 600;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
