export const ACTIONS_TYPES = {
  DETAILS_MIGRATION: {
    type: "details",
    tooltipTitle: "Details",
    isTooltip: true,
  },
  RESTART_MIGRATION: {
    type: "restartMigration",
    tooltipTitle: "Restart",
    isTooltip: true,
  },
  EDIT_MIGRATION: {
    type: "edit",
    title: "Edit",
    tooltipTitle: "Edit",
    isTooltip: true,
  },
  CHANGE_STATUS_MIGRATION: {
    type: "changeStatus",
    tooltipTitle: "Change Status",
    isTooltip: true,
  },
  AERO_SQL: {
    type: "aerosql",
    tooltipTitle: "AeroSql",
    isTooltip: true,
  },
  VIEW_MIGRATION: {
    type: "viewMigration",
    tooltipTitle: "View Migrations",
    isTooltip: true,
  },
  VIEW_DEBUG: {
    type: "viewDebug",
    tooltipTitle: "View Debugging/Connector Logs",
    isTooltip: true,
  },
  VIEW_LOGS: {
    type: "viewLogs",
    tooltipTitle: "View Logs",
    isTooltip: true,
  },
  DELETE: {
    type: "delete",
    title: "Delete",
    tooltipTitle: "Delete",
    isTooltip: true,
  },
  RESET_PACKS: {
    type: "resetPacks",
    tooltipTitle: "Reset Entity Packs",
    isTooltip: true,
  },
  VIEW_LOGS_MODAL: {
    type: "viewLogs",
    tooltipTitle: "View Log",
    isTooltip: true,
  },
  SHOW_DESCRIPTION: {
    type: "details",
    tooltipTitle: "Show description",
    isTooltip: true,
  },
  EDIT_PACKAGE: {
    type: "edit",
    title: "Edit Package",
    tooltipTitle: "Edit Package",
    isTooltip: true,
  },
  SET_CURRENCIES_PRICES: {
    type: "balance",
    title: "Set Currencies Prices",
    tooltipTitle: "Set Currencies Prices",
    isTooltip: true,
  },
  SHOW_CART_PREVIEW: {
    type: "details",
    tooltipTitle: "Show Cart Preview",
    isTooltip: true,
  },
  INFO_DOCKER: {
    type: "details",
    tooltipTitle: "Info docker",
    isTooltip: true,
  },
  TERMINATE_INSTANCE: {
    type: "delete",
    title: "Terminate instance",
    tooltipTitle: "Terminate instance",
    isTooltip: true,
  },
  VIEW: {
    type: "viewLogs",
    tooltipTitle: "View",
    isTooltip: true,
  },
  DELETE_ENTITY: {
    type: "delete",
    title: "Delete Entity",
    tooltipTitle: "Delete Entity",
    isTooltip: true,
  },
  SHOW_STEPS: {
    type: "viewLogs",
    tooltipTitle: "Show Steps",
    isTooltip: true,
  },
  EDIT_PRICE_INTERVALES: {
    type: "viewLogs",
    tooltipTitle: "Edit Price Intervales",
    isTooltip: true,
  },
  SHOW_DETAILS: {
    type: "viewLogs",
    tooltipTitle: "Show details",
    isTooltip: true,
  },
  ACCOUNT_MIG_LOGS: {
    type: "viewLogs",
    tooltipTitle: "Account Migration Logs",
    isTooltip: true,
  },
  ADD_TRANSACTON: {
    type: "transaction",
    tooltipTitle: "Add transaction",
    isTooltip: true,
  },
  BALANCE_HISTORY: {
    type: "balanceHistory",
    tooltipTitle: "Balance history",
    isTooltip: true,
  },
  LOGIN: {
    type: "loginUser",
    tooltipTitle: "Login as user",
    isTooltip: true,
  },
  INFO: {
    type: "viewLogs",
    tooltipTitle: "Info exception",
    isTooltip: true,
  },
  INFO_PAYLOAD: {
    type: "details",
    tooltipTitle: "Show datails",
    isTooltip: true,
  },
};

export const HEADER_ACTIONS_TYPES = {
  EXPORT_CSV: {
    type: "exportCSV",
    title: "Export into CSV",
    isIcon: false,
  },
  FILTERS: {
    type: "filters",
    title: "Filters",
    isIcon: false,
  },
  RELOAD: {
    type: "reload",
    title: "Reload",
    isIcon: false,
  },
};

export const actionsByTableName = (name) => {
  if (name === "Migrations List") {
    return [
      ACTIONS_TYPES.DETAILS_MIGRATION,
      ACTIONS_TYPES.RESTART_MIGRATION,
      ACTIONS_TYPES.EDIT_MIGRATION,
      ACTIONS_TYPES.CHANGE_STATUS_MIGRATION,
      ACTIONS_TYPES.AERO_SQL,
      ACTIONS_TYPES.VIEW_MIGRATION,
      ACTIONS_TYPES.VIEW_DEBUG,
      ACTIONS_TYPES.VIEW_LOGS,
    ];
  }

  if (name === "Balance history") {
    return [ACTIONS_TYPES.EDIT_MIGRATION];
  }

  if (name === "Entities Packs From Migration") {
    return [ACTIONS_TYPES.EDIT_MIGRATION, ACTIONS_TYPES.RESET_PACKS];
  }

  if (name === "View Logs") {
    return [ACTIONS_TYPES.VIEW_LOGS_MODAL];
  }

  if (name === "Service Log" || name === "Cart Preview Logs") {
    return [ACTIONS_TYPES.VIEW_LOGS_MODAL];
  }

  if (name === "Migrations with errors" || name === "Pending migrations") {
    return [
      ACTIONS_TYPES.VIEW_MIGRATION,
      ACTIONS_TYPES.RESTART_MIGRATION,
      ACTIONS_TYPES.VIEW_LOGS,
      ACTIONS_TYPES.CHANGE_STATUS_MIGRATION,
    ];
  }

  if (name === "Active migrations") {
    return [
      ACTIONS_TYPES.VIEW_MIGRATION,
      ACTIONS_TYPES.VIEW_LOGS,
      ACTIONS_TYPES.CHANGE_STATUS_MIGRATION,
    ];
  }

  if (
    name === "Completed migrations" ||
    name === "Waiting for start" ||
    name === "Wait For Client Reply"
  ) {
    return [ACTIONS_TYPES.VIEW_MIGRATION, ACTIONS_TYPES.VIEW_LOGS];
  }

  if (name === "Migrations Options") {
    return [ACTIONS_TYPES.SHOW_DESCRIPTION];
  }

  if (name === "Price Packages List") {
    return [ACTIONS_TYPES.EDIT_PACKAGE, ACTIONS_TYPES.SET_CURRENCIES_PRICES];
  }

  if (name === "Edit packages" || name === "Configuration Table") {
    return [ACTIONS_TYPES.EDIT_MIGRATION];
  }

  if (name === "Messages Queues") {
    return [ACTIONS_TYPES.INFO, ACTIONS_TYPES.INFO_PAYLOAD];
  }

  if (
    name === "Prepared Cart Preview Stores" ||
    name === "Cart Preview Stores"
  ) {
    return [ACTIONS_TYPES.SHOW_CART_PREVIEW, ACTIONS_TYPES.DELETE];
  }

  if (name === "Cart Preview Configuration") {
    return [ACTIONS_TYPES.EDIT_MIGRATION];
  }

  if (name === "Remigrations Statistics" || name === "Ticket Surveys") {
    return [ACTIONS_TYPES.DETAILS_MIGRATION];
  }

  if (name === "Migrations Instances") {
    return [ACTIONS_TYPES.INFO_DOCKER, ACTIONS_TYPES.TERMINATE_INSTANCE];
  }

  if (name === "Debug Statistics Items") {
    return [ACTIONS_TYPES.VIEW, ACTIONS_TYPES.DELETE_ENTITY];
  }

  if (name === "Setuped Wizard Log" || name === "Unsetuped Wizard Log") {
    return [ACTIONS_TYPES.SHOW_STEPS];
  }

  if (name === "Migrations Starter") {
    return [ACTIONS_TYPES.DELETE];
  }

  if (name === "Price Rules") {
    return [
      ACTIONS_TYPES.EDIT_MIGRATION,
      ACTIONS_TYPES.EDIT_PRICE_INTERVALES,
      ACTIONS_TYPES.DELETE,
    ];
  }

  if (name === "Migration Survey") {
    return [ACTIONS_TYPES.SHOW_DETAILS];
  }

  if (name === "Accounts List") {
    return [
      ACTIONS_TYPES.EDIT_MIGRATION,
      ACTIONS_TYPES.DELETE,
      ACTIONS_TYPES.ADD_TRANSACTON,
      ACTIONS_TYPES.BALANCE_HISTORY,
      ACTIONS_TYPES.ACCOUNT_MIG_LOGS,
      ACTIONS_TYPES.LOGIN,
    ];
  }

  if (name === "detailsModal") {
    return [ACTIONS_TYPES.VIEW_ACCOUNT, ACTIONS_TYPES.DELETE];
  }

  return [ACTIONS_TYPES.EDIT_MIGRATION, ACTIONS_TYPES.DELETE];
};

export const standartHeaderActions = () => [
  HEADER_ACTIONS_TYPES.EXPORT_CSV,
  HEADER_ACTIONS_TYPES.FILTERS,
  HEADER_ACTIONS_TYPES.RELOAD,
];
