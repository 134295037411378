import React from "react";

import { actionsTheme } from "../../constants";
import { useTheme } from "../../../../hooks/useTheme";

import TableHeader from "../../../../components/TableMacros/components/TableHeader";

import { Container } from "./styles";

const ThemeSetting = ({ onClickAction }) => {
  const { theme, changeTheme } = useTheme();

  const onChange = () => {
    changeTheme(theme === "main" ? "testTheme" : "main");

    onClickAction();
  };

  return (
    <Container>
      <TableHeader actions={actionsTheme} redirectByType={onChange} />
    </Container>
  );
};

export default ThemeSetting;
