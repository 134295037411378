import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  migrations: [],
};

export const typeConnectSlice = createSlice({
  name: "wizardTypeConnect",
  initialState,

  reducers: {
    setNewMigration: (state, { payload }) => {
      const index = state.migrations.findIndex(
        (obj) => obj.migrationId === payload.migrationId,
      );

      if (index !== -1) {
        state.migrations[index] = { ...state.migrations[index], ...payload };
      } else {
        state.migrations.push(payload);
      }
    },
  },
});

export const { setNewMigration } = typeConnectSlice.actions;

export const selectMigrations = (state) => state.wizardTypeConnect.migrations;

export default typeConnectSlice.reducer;
