import { createSlice } from "@reduxjs/toolkit";

import { packageService } from "./packageService";

const initialState = {
  order_id: "",
  previous_order_id: "",
  source_store: {},
  source_store_access: {},
  target_store: {},
  target_store_access: {},
  checked_source_url: false,
  checked_source_access_fields: false,
  checked_target_url: false,
  checked_target_access_fields: false,
};

export const packageSlice = createSlice({
  name: "package",
  initialState,

  reducers: {
    setSourceCheckedUrl: (state, { payload }) => {
      state.checked_source_url = payload;
    },

    setSourceCheckedAccessFields: (state, { payload }) => {
      state.checked_source_access_fields = payload;
    },

    setTargetCheckedUrl: (state, { payload }) => {
      state.checked_target_url = payload;
    },

    setTargetCheckedAccessFields: (state, { payload }) => {
      state.checked_target_access_fields = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      packageService.endpoints.savePackage.matchFulfilled,
      (state, { payload }) => {
        const newOrderId = payload?.payload?.order_id;
        if (state.order_id !== newOrderId) {
          return {
            ...initialState,
            order_id: newOrderId,
            previous_order_id: state.order_id,
          };
        }
        return state;
      }
    );

    builder.addMatcher(
      packageService.endpoints.saveSourceStore.matchFulfilled,
      (state, { payload }) => {
        state.source_store = payload?.payload?.source_store;
      },
    );

    builder.addMatcher(
      packageService.endpoints.saveSourceStoreAccess.matchFulfilled,
      (state, { payload }) => {
        state.source_store_access = payload?.payload?.source_store;
      },
    );

    builder.addMatcher(
      packageService.endpoints.saveTargetStore.matchFulfilled,
      (state, { payload }) => {
        state.target_store = payload?.payload?.target_store;
      },
    );

    builder.addMatcher(
      packageService.endpoints.saveTargetStoreAccess.matchFulfilled,
      (state, { payload }) => {
        state.target_store_access = payload?.payload?.target_store;
      },
    );

    builder.addMatcher(
      packageService.endpoints.saveAcceptOrder.matchFulfilled,
      () => initialState
    );
  },
});

export const { setSourceCheckedUrl, setSourceCheckedAccessFields, setTargetCheckedUrl, setTargetCheckedAccessFields } = packageSlice.actions;
export const selectOrderId = (state) => state.package.order_id;
export const selectSourceStore = (state) => state.package.source_store;
export const selectSourceStoreAccess = (state) =>
  state.package.source_store_access;
export const selectTargetStore = (state) => state.package.target_store;
export const selectTargetStoreAccess = (state) =>
  state.package.target_store_access;
export const selectSourceCheckedUrl = (state) => state.package.checked_source_url;
export const selectSourceCheckedAccessFields = (state) =>
  state.package.checked_source_access_fields
export const selectTargetCheckedUrl = (state) => state.package.checked_target_url;
export const selectTargetCheckedAccessFields = (state) =>
  state.package.checked_target_access_fields

export default packageSlice.reducer;
