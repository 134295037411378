import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  height: fit-content;
  line-height: 0;
  transition: 0.5s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
