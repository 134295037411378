import React, { useState } from "react";
import { Radio } from "antd";
import DynamicSvgActions from "../../../../components/UI/DynamicSvgActions";
import RadioCart from "../RadioCart";

import { Container, Error } from "./styles";

const QuizType = ({ formState, data, modalType, type, error }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const onClick = (value) => {
    setSelectedValue(value);
    formState.setValue(type, value);
  };

  return (
    <Container $modalType={modalType}>
      <Radio.Group value={selectedValue}>
        {data?.answers?.map((item) => (
          <RadioCart
            key={item.type}
            onChangeRate={onClick}
            type={item.type}
            icon={<DynamicSvgActions imageName={item.type} path="answers" />}
            label={item.label}
            name={type}
            selectedValue={selectedValue}
            modalType={modalType}
            error={error}
          />
        ))}
      </Radio.Group>

      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default QuizType;
