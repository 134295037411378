import React, { memo } from "react";

import AuthButton from "../AuthButton";

import { Container, Text } from "./styles";

const AuthForgotPasswordSuccess = ({ goToSignIn }) => (
  <Container>
    <Text>
      Password recovery instructions has been sent to your email. Please check
      your inbox, or click button below.
    </Text>

    <AuthButton onSubmit={goToSignIn} isValid>
      Sign In
    </AuthButton>
  </Container>
);

export default memo(AuthForgotPasswordSuccess);
