import React, { memo } from "react";
import { Dropdown } from "antd";

import { ReactComponent as ArrowIcon } from "../../../assets/images/expandFalse.svg";

import { StyledTitle, StyledLink } from "./styles";

const DropdownMenu = ({ title, items, placement = "top", to }) => (
  <Dropdown
    className="customDropdown"
    mouseLeaveDelay={0.7}
    menu={{
      items,
    }}
    arrow
    placement={placement}
  >
    {to ? (
      <StyledLink to={to}>
        {title}

        <ArrowIcon />
      </StyledLink>
    ) : (
      <StyledTitle onClick={(e) => e.preventDefault()}>
        {title}
        <ArrowIcon />
      </StyledTitle>
    )}
  </Dropdown>
);

export default memo(DropdownMenu);
