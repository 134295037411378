import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectMigrationSession } from "../store/wizardCreate/wizardCreateSlice";

export const useMigrationSession = () => {
  const migrationSession = useSelector(selectMigrationSession);

  return useMemo(() => migrationSession, [migrationSession]);
};
