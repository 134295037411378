import React from "react";

import ModalMacros from "../ModalMacros/components/ModalMacros";
import RegistrationQuizMain from "../../modules/RegistrationQuiz";

const ModalRole = ({ open, loading, close }) => (
  <ModalMacros
    title="Choose your role, to personalize your app journey."
    open={open}
    loading={loading}
    footer={false}
    closable={false}
  >
    <RegistrationQuizMain
      modalType="finishFirstRegistrationQuiz"
      close={close}
    />
  </ModalMacros>
);

export default ModalRole;
