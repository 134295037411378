/* eslint-disable no-unused-expressions */
import moment from "moment";

export const transformDate = (date, formatDate) =>
  moment(date).format(formatDate);

export const getNameAccount = (accountId, mappings) => {
  let res = "";
  mappings?.forEach((elem) => {
    elem.key === accountId ? (res = elem.text) : "";
  });

  return res || "no-name";
};

export const isPositive = (string) => (string?.charAt(0) !== "-" ? "true" : "");

export const creditToDolars = (int) => (int / 100).toFixed(2);

export const deleteHandle = async (
  deleteItem,
  id,
  onButton,
  type,
  onCancel,
) => {
  try {
    await deleteItem(id).unwrap();

    onButton(type);

    if (onCancel) {
      onCancel();
    }
  } catch (error) {
    console.error(error);
  }
};

export const transformYesNoParam = (type) => {
  const paramConfig = {
    No: 0,
    no: 0,
    Yes: 1,
    yes: 1,
  };

  return type ? paramConfig[type] : "";
};
