import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 0 24px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  gap: 0 24px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const CollapseWrapper = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 767px) {
    display: flex;
  }
`;
