import React from "react";
import { useDispatch } from "react-redux";
import { useDrawer } from "../../../hooks/useDrawer";
import { setShowDrawer } from "../../../store/authentication/authSlice";
import { ReactComponent as MenuHumbOutlined } from "../../../assets/images/menuHumb.svg";
import Drawer from "../Drawer";

import { Container, MenuHamb, WrapperText } from "./styles";

const ResponseMenuUser = ({ menu, children }) => {
  const dispatch = useDispatch();
  const showDrawer = useDrawer();

  const onShowDrawer = () => dispatch(setShowDrawer(true));
  const onClose = () => dispatch(setShowDrawer(false));

  return (
    <Container>
      <WrapperText>{children}</WrapperText>

      <MenuHamb onClick={onShowDrawer}>
        <MenuHumbOutlined />
      </MenuHamb>

      <MenuHamb>
        <Drawer open={showDrawer} onClose={onClose} menu={menu} />
      </MenuHamb>
    </Container>
  );
};

export default ResponseMenuUser;
