import React, { memo } from "react";
import { useFormTo } from "../../hooks/useFormTo";

import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import { ReactComponent as FooterLogo } from "../../assets/images/FooterLogo.svg";

import { StyledLink } from "./styles";

const LogoLink = ({ to = "/migrations", isFooter }) => {
  const { prefix } = useFormTo();

  return (
    <StyledLink to={`${prefix}${to}`}>
      {isFooter ? <FooterLogo /> : <Logo />}
    </StyledLink>
  );
};

export default memo(LogoLink);
