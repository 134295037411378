import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../store/authentication/authService";

export const useAuth = () => {
  const { access_token, user } = useSelector(selectCurrentUser);

  return useMemo(() => ({ user, access_token }), [user, access_token]);
};
