import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  width: fit-content;

  .ant-modal-footer .ant-btn-primary {
    display: ${({ hideOK }) => hideOK && "none"};
  }
`;
