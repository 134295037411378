import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0 50px;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    gap: 50px;
  }
`;

export const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Address = styled.address`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;

  @media (max-width: 767px) {
    align-items: center;
  }
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.primary};
`;

export const TargetLink = styled.a`
  margin: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.primary};
  display: block;
  transition: all 0.5s ease;

  &:hover {
    color: ${({ theme }) => theme.pallette.blue.link};
  }
`;

export const Email = styled(TargetLink)`
  margin: 0;
`;

export const Phone = styled(TargetLink)`
  display: inline;
  margin: 0;
`;
