import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 584px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.pallette.silver.primary};
  margin: 0 8px;
`;

export const Divider = styled.div`
  width: 100%;
  max-width: 268.5px;
  height: 1px;
  background: ${({ theme }) => theme.pallette.silver.secondary};
`;
