import { BaseQueryParams } from "../baseQuery";

export const accountsService = BaseQueryParams("accountsService", [
  "ADMIN_ACCOUNTS",
  "USER_BY_ID",
]).injectEndpoints({
  endpoints: (builder) => ({
    getAdminUsers: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/users${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["ADMIN_ACCOUNTS"],
    }),

    deleteAdminUser: builder.mutation({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["ADMIN_ACCOUNTS"],
    }),

    createAdminUser: builder.mutation({
      query: (body) => ({
        url: "/admin/users",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["ADMIN_ACCOUNTS"],
    }),

    editAdminUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/users/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["ADMIN_ACCOUNTS", "USER_BY_ID"],
    }),

    getUserById: builder.query({
      query: ({ userId }) => ({
        url: `admin/users/${userId}`,
        method: "GET",
      }),

      providesTags: () => ["USER_BY_ID"],
    }),

    getAdminPayments: builder.query({
      query: () => ({
        url: `/admin/users/payments`,
        method: "GET",
      }),
    }),

    getBalanceHistory: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/balance-history${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
    }),

    addTransaction: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/users/${id}/money`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["ADMIN_ACCOUNTS"],
    }),

    getMessage: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations/account/messages${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getFeedbacks: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/user-feedbacks${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetAdminUsersQuery,
  useDeleteAdminUserMutation,
  useCreateAdminUserMutation,
  useEditAdminUserMutation,
  useGetAdminPaymentsQuery,
  useGetBalanceHistoryQuery,
  useAddTransactionMutation,
  useGetMessageQuery,
  useGetUserByIdQuery,
  useGetFeedbacksQuery,
} = accountsService;
