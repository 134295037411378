import React from "react";
import LoadingModal from "../../../LoadingModal";
import Header from "../Header";

import { StyledModal } from "./styles";

const ModalMacros = ({
  title,
  onCancel,
  open,
  onOk,
  children,
  footer,
  isLoading,
  ...rest
}) => (
  <StyledModal
    title={<Header title={title} />}
    onCancel={onCancel}
    open={open}
    onOk={onOk}
    footer={footer}
    {...rest}
  >
    <>
      <LoadingModal isLoading={isLoading} />
      {children}
    </>
  </StyledModal>
);

export default ModalMacros;
