import React from "react";

import { ReactComponent as InfoIcon } from "../../../assets/images/InfoIcon.svg";
import Tooltip from "../../UI/Tooltip/Tooltip";
import { LOYALTY_LEVELS } from "../../../constants/loyalty-level";
import { useFormTo } from "../../../hooks/useFormTo";

import { Container, Label, LabelBold, Paragraph, StyledLink } from "./styles";

const HeaderPartnerLevel = ({ level }) => {
  const { prefix } = useFormTo();
  return (
    <Container>
      <Label>Loyalty Program Level: </Label>
      <Label>
        <LabelBold
          dangerouslySetInnerHTML={{
            __html: LOYALTY_LEVELS[level]?.level,
          }}
        />
        <Tooltip
          title={
            <Paragraph>
              There are a total of four Loyalty Program levels, ranging from
              bronze to platinum. Read more{" "}
              <StyledLink to={`${prefix}/profile#loyalty_program`}>
                here.
              </StyledLink>
            </Paragraph>
          }
          color="white"
        >
          <InfoIcon />
        </Tooltip>
      </Label>
    </Container>
  );
};

export default HeaderPartnerLevel;
