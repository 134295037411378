import React, { useMemo } from "react";
import { ThemeProvider as Provider } from "styled-components";

import { useTheme } from "../../hooks/useTheme";

import { mainTheme } from "../../theme/mainTheme";
import { testTheme } from "../../theme/testTheme";

const ThemeProvider = ({ children }) => {
  const { theme } = useTheme();
  const userTheme = useMemo(
    () => (theme === "testTheme" ? testTheme : mainTheme),
    [theme],
  );

  return <Provider theme={userTheme}>{children}</Provider>;
};

export default ThemeProvider;
