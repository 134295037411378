import { AUTH_PATH } from "../router/path/auth";
import { WIZARD_STEP_PATH } from "../router/path/wizard";

export const setRightRoute = (prefix, route) => {
  const authRoutes = Object.values(AUTH_PATH).flatMap((item) => [
    `/${item}`,
    `/${item}/`,
  ]);
  const wizardRoutes = Object.values(WIZARD_STEP_PATH).flatMap((item) => [
    `/wizard/${item}`,
    `/wizard/${item}/`,
  ]);

  if (
    authRoutes.includes(route) ||
    wizardRoutes.includes(route) ||
    route === `/${prefix}` ||
    route === `/${prefix}/`
  ) {
    return "";
  }

  return route || "";
};
