import styled from "styled-components";
import { Checkbox } from "antd";

export const Container = styled.div`
  width: 100%;
  max-width: 584px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.pallette.silver.primary};
    width: 100%;

    @media (max-width: 1200px) {
      font-size: calc(12px + (14 - 12) * ((100vw - 400px) / (1200 - 400)));
    }

    .ant-checkbox-inner {
      border-color: ${({ $isError, theme }) =>
        $isError && theme.pallette.red.border};
    }
  }
`;

export const Link = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.pallette.blue.link};

  @media (max-width: 1200px) {
    font-size: calc(12px + (14 - 12) * ((100vw - 400px) / (1200 - 400)));
  }
`;

export const Error = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.red.error};
  margin-top: 8px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
`;
