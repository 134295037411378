import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectTheme, setNewTheme } from "../store/settings/settingsSlice";

export const useTheme = () => {
  const { theme } = useSelector(selectTheme);
  const dispatch = useDispatch();

  const changeTheme = useCallback(
    (newTheme) => dispatch(setNewTheme(newTheme)),
    [],
  );

  return {
    theme,
    changeTheme,
  };
};
