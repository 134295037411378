import React, { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { ADMIN_PATH } from "../../router/path/admin";
import { ADMIN_PERMISSIONS } from "../../constants/permissions";
import { useAuth } from "../../hooks/useAuth";
import { hasRequiredPermissions } from "../../utils";

const PermissionsProvider = () => {
  const { user } = useAuth();
  const hasPermissions = useMemo(
    () => hasRequiredPermissions(user?.abilities, ADMIN_PERMISSIONS),
    [user?.abilities],
  );

  return hasPermissions ? (
    <Outlet />
  ) : (
    <Navigate to={ADMIN_PATH.MIGRATIONS} replace />
  );
};

export default PermissionsProvider;
