import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.pallette.blue.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 0 5px;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.6;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.pallette.white.main};
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.5s ease;
  gap: 0 5px;
  display: flex;
  align-items: center;

  svg {
    path {
      stroke: ${({ theme }) => theme.pallette.white.main};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.pallette.blue.light};

    svg {
      path {
        stroke: ${({ theme }) => theme.pallette.blue.light};
      }
    }
  }
`;
