import { createSlice } from "@reduxjs/toolkit";

import { migrationsService } from "./migrationsService";

const initialState = {
  migrations: [],
  pagination: {
    count: 0,
    currentPage: 1,
    total: 0,
    totalPages: 0,
  },
};

export const migrationsSlice = createSlice({
  name: "userMigrations",
  initialState,

  reducers: {
    setNewPage: (state, { payload }) => {
      state.pagination.currentPage = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      migrationsService.endpoints.getMigrationsList.matchFulfilled,
      (state, { payload }) => {
        state.migrations = payload?.payload?.migrations?.data;
      },
    );

    builder.addMatcher(
      migrationsService.endpoints.getMyMigrationsList.matchFulfilled,
      (state, { payload }) => {
        state.pagination.count =
          payload?.payload?.migrations?.pagination?.count || 0;
        state.pagination.currentPage =
          payload?.payload?.migrations?.pagination?.current_page || 1;
        state.pagination.total =
          payload?.payload?.migrations?.pagination?.total || 0;
        state.pagination.totalPages =
          payload?.payload?.migrations?.pagination?.total_pages || 0;
      },
    );
  },
});

export const { setNewPage } = migrationsSlice.actions;

export const selectMigrations = (state) => state.userMigrations.migrations;
export const selectMigrationsPagination = (state) =>
  state.userMigrations.pagination;

export default migrationsSlice.reducer;
