export const NOT_YET = "not_yet";
export const BRONZE = "bronze";
export const SILVER = "silver";
export const GOLD = "gold";
export const PLATINUM = "platinum";

export const ACHIEVED_LOYALTY_LEVELS = [
  BRONZE,
  SILVER,
  GOLD,
  PLATINUM,
];

export const LOYALTY_LEVELS = {
  [NOT_YET]: {
    background: "#00AEEF",
    shadow: "0px 0px 0px 5px rgb(0, 174, 239)",
    level: "Not yet",
  },
  [BRONZE]: {
    background: "linear-gradient(154.6deg, #C68860 23%, #D19B6C 117.77%)",
    shadow: "0px 0px 0px 7px rgb(248 235 223)",
    level: "Bronze<br> level",
  },
  [SILVER]: {
    background: "linear-gradient(157.85deg, #C1C1C1 10.42%, #EDEFF1 144.04%)",
    shadow: "0px 0px 0px 7px rgb(244 244 244)",
    level: "Silver<br> level",
  },
  [GOLD]: {
    background: "linear-gradient(154.6deg, #E2C42A 23%, #F8E9BD 117.77%)",
    shadow: "0px 0px 0px 7px rgb(251 247 198)",
    level: "Gold<br> level",
  },
  [PLATINUM]: {
    background: "linear-gradient(157.85deg, #0EB6FD 10.42%, #90FBE1 107.62%)",
    shadow: "0px 0px 0px 7px rgb(234 247 248)",
    level: "Platinum<br> level",
  },
};
