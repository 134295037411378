import styled from "styled-components";

export const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  gap: 0 24px;
`;

export const StyledLi = styled.li``;

export const Link = styled.a`
  color: ${({ theme }) => theme.pallette.white.main};
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.5s ease;

  &:hover {
    color: ${({ theme }) => theme.pallette.blue.light};
  }
`;
