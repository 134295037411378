import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme, $hiddenMenu }) =>
      $hiddenMenu ? theme.pallette.silver.light : "transparent"};
  padding-bottom: 12px;

  @media (max-width: 767px) {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
