import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../store/authentication/authService";
import { ACHIEVED_LOYALTY_LEVELS } from "../constants/loyalty-level";
import { hasRequiredPermissions } from "../utils";

export const useLoyaltyPermission = () => {
  const { user } = useSelector(selectCurrentUser);

  return useMemo(
    () => hasRequiredPermissions(ACHIEVED_LOYALTY_LEVELS, [user?.loyalty_level]),
    [user?.loyalty_level],
  );
};
