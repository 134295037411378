import React, { memo } from "react";

import LogoLink from "../../LogoLink";
import ContactInfo from "../ContactInfo";

import { Container } from "./styles";

const TopSection = () => (
  <Container>
    <LogoLink isFooter />

    <ContactInfo />
  </Container>
);
export default memo(TopSection);
