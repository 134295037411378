import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  border-right: 1px solid ${({ theme }) => theme.pallette.silver.light};
  margin-right: 40px;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.pallette.silver.dark};
`;

export const SubTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.pallette.silver.dark};
`;
