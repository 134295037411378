import React from "react";
import { Helmet } from "react-helmet";

const MetaPixel = () => (
  <Helmet>
    <script key="function" id="facebook-pixel-script">
      {`
      var fbPid = ${process.env?.REACT_APP_FB_PIXEL_ID};
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      window.fbq('init', ${process.env?.REACT_APP_FB_PIXEL_ID});
      window.fbq('track', 'PageView');
      `}
    </script>

    <noscript key="image" id="facebook-pixel-image">
      {`
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=${process.env?.REACT_APP_FB_PIXEL_ID}&ev=PageView&noscript=1"
          alt="Pixel img"
        />
      `}
    </noscript>
  </Helmet>
);

export default MetaPixel;
