import React, { memo } from "react";

import TopSection from "./TopSection";
import BottomSection from "./BottomSection";

import { StyledFooter } from "./styles";

const Footer = () => (
  <StyledFooter>
    <TopSection />

    <BottomSection />
  </StyledFooter>
);

export default memo(Footer);
