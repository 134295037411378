import { BaseQueryParams } from "../baseQuery";

export const wizardCreateService = BaseQueryParams("wizardService", [
  "Account_Selection",
  "MAPPING",
  "Select_Source",
  "MIGRATION_SESSION",
  "CHANGE_MIGRATION",
  "SURVEY",
  "DETAILS_MIGRATION",
  "RATE",
  "CONTINUE_MIGRATION",
]).injectEndpoints({
  endpoints: (builder) => ({
    getMigrationSession: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration",
        method: "GET",
      }),

      providesTags: () => ["MIGRATION_SESSION"],
    }),

    getMigrationProgress: builder.query({
      query: ({ migration_id }) => ({
        url: `migrations/${migration_id}/details/progress`,
        method: "GET",
      }),
    }),

    getNewMigrationSession: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration",
        method: "GET",
      }),
    }),

    getMigrationDetails: builder.query({
      query: ({ migration_id }) => ({
        url: `migrations/${migration_id}/details`,
        method: "GET",
      }),

      providesTags: () => ["DETAILS_MIGRATION"],
    }),

    createMigration: builder.mutation({
      query: () => ({
        url: "/migrations/wizard/migration",
        method: "POST",
      }),
    }),

    continueMigrationSession: builder.mutation({
      query: (migrationId) => ({
        url: `/migrations/wizard/migration/${migrationId}`,
        method: "PUT",
        body: { reset: 0 },
      }),

      // invalidatesTags: () => ["CONTINUE_MIGRATION"],
      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    editMigrationSession: builder.mutation({
      query: (migrationId) => ({
        url: `/migrations/wizard/migration/${migrationId}`,
        method: "PUT",
        body: { reset: 1 },
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    getEmails: builder.query({
      query: ({ email }) => ({
        url: `/migrations/wizard/get-accounts${
          email ? `?search=${encodeURIComponent(email)}` : ""
        }`,
        method: "GET",
      }),

      // providesTags: () => ["Account_Selection"],
    }),

    applyMigrationToAccount: builder.mutation({
      query: (account_email) => ({
        url: "/migrations/wizard/account-selection",
        method: "POST",
        body: { account_email },
      }),
    }),

    getAvailableStores: builder.query({
      query: () => ({
        url: "/migrations/wizard/available-stores",
        method: "GET",
      }),

      providesTags: () => ["Select_Source"],
    }),

    setupStores: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/stores-setup",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MAPPING"],
    }),

    checkTypeConnect: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/cart-fields",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION", "NOTICE"],
    }),

    getDownloadBridge: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/bridge",
        method: "GET",
      }),
    }),

    // Connects method //

    connectStoreBridge: builder.mutation({
      query: ({ url, type }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/bridge`,
        method: "POST",
        body: { url },
      }),
    }),

    connectFtp: builder.mutation({
      query: ({ data, type }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/ftp`,
        method: "POST",
        body: data,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    connectionApiStore: builder.mutation({
      query: ({ body, type }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/api`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    connectionCustomStore: builder.mutation({
      query: ({ body, type, connectionType }) => ({
        url: `/migrations/wizard/migration/store/${type}/connection/${connectionType}`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    // Connects method //

    // SETTINGS //
    getEntities: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/entities-selection",
        method: "GET",
      }),
      // providesTags: () => ["CONTINUE_MIGRATION"],
      invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    requiredPlugins: builder.mutation({
      query: ({ body, type }) => ({
        url: `/migrations/wizard/check-required-plugins`,
        method: "POST",
        body,
      }),
    }),

    setEntities: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/options",
        method: "POST",
        body,
      }),

      // invalidatesTags: () => ["MIGRATION_SESSION", "MAPPING"],
    }),

    getOptions: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/options",
        method: "POST",
        body,
      }),
    }),

    setOptions: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/options",
        method: "POST",
        body,
      }),
    }),

    getMappingOption: builder.query({
      query: (reload) => ({
        url: `/migrations/wizard/mapping${reload ? "/reload" : ""}`,
        method: "GET",
      }),

      providesTags: () => ["MAPPING"],
    }),

    setMapping: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/mapping",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MAPPING"],
    }),

    getNoticesByStep: builder.query({
      query: ({ step }) => ({
        url: `/migrations/wizard/notices${step ? `/${step}` : ""}`,
        method: "GET",
      }),

      providesTags: () => ["NOTICE"],
    }),

    // DEMO
    startDemo: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/migration/demo",
        method: "POST",
        body,
      }),
    }),

    getDemoProgressEntities: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/demo/entities",
        method: "GET",
      }),
    }),

    getSurvey: builder.query({
      query: () => ({
        url: "/migrations/wizard/survey",
        method: "GET",
      }),

      providesTags: () => ["SURVEY"],
    }),

    setSurvey: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/survey",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["SURVEY"],
    }),

    getDemoResult: builder.query({
      query: () => ({
        url: "/migrations/wizard/migration/demo/result",
        method: "GET",
      }),
    }),

    getDemoRate: builder.query({
      query: ({ id }) => ({
        url: `/migrations/wizard/demo/rate/${id}`,
        method: "GET",
      }),
    }),

    setDemoRate: builder.mutation({
      query: ({ id, body }) => ({
        url: `/migrations/wizard/demo/rate/${id}`,
        method: "POST",
        body,
      }),
    }),
    // DEMO

    // FULL
    getInsurances: builder.query({
      query: () => ({
        url: "/migrations/wizard/insurances",
        method: "GET",
      }),
    }),

    sendInsurances: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/insurance",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["DETAILS_MIGRATION"],
    }),

    setOptionsFull: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/options",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["DETAILS_MIGRATION"],
    }),

    sendEvents: builder.mutation({
      query: (body) => ({
        url: "/send-events",
        method: "POST",
        body,
      }),
    }),

    setCoupon: builder.mutation({
      query: (code) => ({
        url: "/migrations/wizard/apply-coupon",
        method: "POST",
        body: { discount_coupon_code: code },
      }),

      invalidatesTags: () => ["DETAILS_MIGRATION"],
    }),

    startFullMigration: builder.mutation({
      query: () => ({
        url: "/migrations/wizard/start-full-migration",
        method: "POST",
        body: {
          rdb: 0,
        },
      }),

      invalidatesTags: () => ["DETAILS_MIGRATION"],
    }),

    validateCartPair: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/cart-pairs/validate",
        method: "POST",
        body,
      }),
    }),

    calculatingRecentDataProcess: builder.mutation({
      query: () => ({
        url: "/migrations/wizard/check-calculating-recent-data-process",
        method: "POST",
      }),

      // invalidatesTags: () => ["MIGRATION_SESSION"],
    }),

    calculateMigrationData: builder.mutation({
      query: () => ({
        url: "/migrations/wizard/calculate-migration-data",
        method: "POST",
      }),

      invalidatesTags: () => ["DETAILS_MIGRATION"],
    }),

    // FULL

    // SETTINGS //

    getResources: builder.query({
      query: ({ entityQuery }) => ({
        url: `/migrations/wizard/resources?${entityQuery}`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["RESOURCES"],
    }),

    uploadResourcesFile: builder.mutation({
      query: (body) => ({
        url: "/migrations/wizard/upload-source-resource",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["RESOURCES"],
    }),

    getMigrationDetailsNotices: builder.mutation({
      query: (body) => ({
        url: "/migrations/notices",
        method: "POST",
        body,
      }),
    }),

    downloadResourceFile: builder.query({
      query: ({ id, body }) => ({
        url: `/migrations/${id}/target/details/download-resource-file`,
        method: "GET",
        params: body,
      }),

      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetMigrationSessionQuery,
  useCreateMigrationMutation,
  useContinueMigrationSessionMutation,
  useGetEmailsQuery,
  useApplyMigrationToAccountMutation,
  useGetAvailableStoresQuery,
  useSetupStoresMutation,
  useCheckTypeConnectMutation,
  useConnectStoreBridgeMutation,
  useConnectFtpMutation,
  useConnectionApiStoreMutation,
  useConnectionCustomStoreMutation,
  useGetEntitiesQuery,
  useSetEntitiesMutation,
  useGetMappingOptionQuery,
  useLazyGetMappingOptionQuery,
  useSetMappingMutation,
  useGetNoticesByStepQuery,
  useLazyGetNoticesByStepQuery,
  useGetOptionsMutation,
  useSetOptionsMutation,
  useStartDemoMutation,
  useGetDemoProgressEntitiesQuery,
  useGetSurveyQuery,
  useSetSurveyMutation,
  useGetDemoResultQuery,
  useGetDemoRateQuery,
  useSetDemoRateMutation,
  useGetInsurancesQuery,
  useSendInsurancesMutation,
  useSetOptionsFullMutation,
  useSendEventsMutation,
  useSetCouponMutation,
  useGetMigrationDetailsQuery,
  useStartFullMigrationMutation,
  useGetNewMigrationSessionQuery,
  useValidateCartPairMutation,
  useEditMigrationSessionMutation,
  useCalculatingRecentDataProcessMutation,
  useCalculateMigrationDataMutation,
  useGetDownloadBridgeQuery,
  useGetMigrationProgressQuery,
  useRequiredPluginsMutation,
  useGetResourcesQuery,
  useUploadResourcesFileMutation,
  useGetMigrationDetailsNoticesMutation,
  useLazyDownloadResourceFileQuery,
} = wizardCreateService;
