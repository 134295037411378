import React, { memo } from "react";
import { Dropdown } from "antd";

import DropdownMenu from "./DropdownMenu";

const UserMenuDropdown = ({ children }) => (
  <Dropdown
    overlayClassName="userMenu"
    placement="bottom"
    mouseLeaveDelay={0.7}
    menu={{
      items: DropdownMenu(),
    }}
    arrow
  >
    {children}
  </Dropdown>
);

export default memo(UserMenuDropdown);
