import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 60px auto;
  background: ${({ theme }) => theme.pallette.white.main};
  box-shadow: 4px 4px 16px -10px rgba(24, 35, 83, 0.1);
  border-radius: 24px;
  padding: 32px 10px;
`;
