import styled, { keyframes } from "styled-components";

const loadAnimation = keyframes`
  20% { background-position: top }
  40% { background-position: bottom }
  60% { background-position: center }
`;

export const Container = styled.div`
  display: ${({ loading }) => (loading ? "flex" : "none")};
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 19, 56, 0.5);
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div`
  width: 10px;
  aspect-ratio: 0.5;
  display: grid;
  background: radial-gradient(#fff 68%, #0000 72%) center/100% 50% no-repeat;
  animation: ${loadAnimation} 1.2s infinite linear calc(var(--_s, 0) * 0.4s);
  transform: translate(calc(var(--_s, 0) * 150%));

  &::before {
    width: 10px;
    aspect-ratio: 0.5;
    display: grid;
    background: radial-gradient(#fff 68%, #0000 72%) center/100% 50% no-repeat;
    animation: ${loadAnimation} 1.2s infinite linear calc(var(--_s, 0) * 0.4s);
    transform: translate(calc(var(--_s, 0) * 150%));
    content: "";
    grid-area: 1/1;
    --_s: 1;
  }

  &::after {
    width: 10px;
    aspect-ratio: 0.5;
    display: grid;
    background: radial-gradient(#fff 68%, #0000 72%) center/100% 50% no-repeat;
    animation: ${loadAnimation} 1.2s infinite linear calc(var(--_s, 0) * 0.4s);
    transform: translate(calc(var(--_s, 0) * 150%));
    content: "";
    grid-area: 1/1;
    --_s: -1;
  }
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.pallette.silver.dark};
  margin-top: 30px;
`;
