import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected: {
    key: "",
    title: "",
  },
};

export const migrationsInfoSlice = createSlice({
  name: "userMigrationsInfo",
  initialState,

  reducers: {
    setSelectedTable: (state, { payload }) => {
      state.selected = payload;
    },
  },
});

export const { setSelectedTable } = migrationsInfoSlice.actions;

export const selectSelectedTable = (state) => state.userMigrationsInfo.selected;
export default migrationsInfoSlice.reducer;
