import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 32px;
  padding-right: 40px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid ${({ theme }) => theme.pallette.silver.light};
  margin-right: 40px;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.pallette.silver.dark};
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const LabelBold = styled(Label)`
  font-weight: 500;
  color: ${({ theme }) => theme.pallette.white.main};
  margin: 0 8px 0 0;

  br {
    display: none;
  }
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.pallette.blue.primary};
  word-break: normal;
  word-wrap: normal;
  overflow-wrap: normal;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.pallette.blue.link};
  text-decoration: underline;
`;
