import React from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import TagManager from "react-gtm-module";
import {useAuth} from "../../hooks/useAuth";

const gb = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.REACT_APP_GROWTH_BOOK_KEY,
  enableDevMode: true,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "experiment_viewed",
        experimentId: experiment.key,
        variationId: result.key,
      },
    });
  },
});
gb.init({
  // Optional, enable streaming updates
  streaming: true,
});

const GrowthBookApp = ({ children }) => {
  const { user } = useAuth();

  gb.setAttributes({
    id: user?.id,
  });

  return (<GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>)
};

export default GrowthBookApp;
