import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px 0;

  && > div {
    justify-content: center;
  }

  .ant-checkbox-wrapper {
    height: auto;
    color: ${({ theme }) => theme.pallette.blue.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .ant-checkbox-group > * {
    flex: auto;
  }

  .ant-checkbox-group {
    max-height: 600px;
    flex-direction: column;
    overflow: auto;
    gap: 16px;
  }

  .ant-checkbox-wrapper:nth-last-child(1) {
    margin-top: 8px;
  }
`;
