import { BaseQueryParams } from "../baseQuery";

export const accessService = BaseQueryParams(
  "accessService",
  [],
).injectEndpoints({
  endpoints: (builder) => ({
    sendAccessForm: builder.mutation({
      query: (body) => ({
        url: `/access`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSendAccessFormMutation } = accessService;
