import React, { memo } from "react";

import { useAuth } from "../../../hooks/useAuth";
import LogoLink from "../../LogoLink";
import HeaderUserMenu from "../../HeaderUserMenu";
import ResponseMenu from "../ResponseMenu";
import ResponseMenuUser from "../ResponseMenuUser";
import HeaderNavigation from "../HeaderNavigation";
import UserNavigation from "../UserNavigation";
import UserDrawerContent from "../UserDrawerContent";

import { Container } from "./styles";

const TopSection = ({ hiddenMenu }) => {
  const { user } = useAuth();

  return (
    <Container align="center" justify="space-between" $hiddenMenu={hiddenMenu}>
      <LogoLink />

      {user?.id ? (
        <ResponseMenuUser menu={<UserDrawerContent />}>
          <HeaderUserMenu />
        </ResponseMenuUser>
      ) : (
        <ResponseMenu
          menu={user?.id ? <UserNavigation /> : <HeaderNavigation />}
        />
      )}
    </Container>
  );
};

export default memo(TopSection);
