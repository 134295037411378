import styled from "styled-components";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  border: 0;
  min-width: 136px;
  height: 42px;
  border-radius: 100px;
  padding: 9px 17px;
  justify-content: center;
  align-items: center;
  background: ${({ reset, theme }) =>
    reset ? theme.pallette.green.disabled : theme.pallette.green.light};

  &.ant-btn-default {
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: ${({ theme }) => theme.pallette.blue.main};
      color: ${({ theme }) => theme.pallette.white.main};
    }
  }

  color: ${({ theme }) => theme.pallette.blue.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
`;
