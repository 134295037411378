import React, { useEffect } from "react";
import * as amplitude from '@amplitude/analytics-browser';

const AmplitudeProvider = ({ children }) => {
  const amplitudeId = process.env.REACT_APP_AMPLITUDE_API_KEY;

  useEffect(() => {
    if (amplitudeId) {
      amplitude.init(amplitudeId, {
        defaultTracking: true,
      });
    }
  }, [amplitudeId]);

  return children;
};

export default AmplitudeProvider;
