import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
  width: 100%;
  max-width: 584px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  position: relative;

  div {
    opacity: 0;
    position: absolute;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const SocialButton = styled(Button)`
  width: 100%;
  max-width: 264px;
  height: 50px;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.pallette.blue.primary};
  border-color: ${({ color }) => color};
  display: flex;
  align-items: center;

  svg {
    margin-right: 25px;
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
`;
