import React from "react";
import { NO_AUTH_PUBLIC_LINK } from "../../../constants/header";

import { StyledUl, StyledLi, Link } from "./styles";

const HeaderNavigation = () => (
  <StyledUl>
    {NO_AUTH_PUBLIC_LINK.map(({ title, link }) => (
      <StyledLi key={title}>
        <Link href={link} target="_blank">
          {title}
        </Link>
      </StyledLi>
    ))}
  </StyledUl>
);

export default HeaderNavigation;
