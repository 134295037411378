import styled from "styled-components";

export const Container = styled.div`
  width: 260px;
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.pallette.silver.secondary}` : "none"};
  padding: 10px 0;
  flex-direction: column;
  margin: 0 16px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.primary};
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.pallette.silver.primary};
  overflow: hidden;
`;

export const LogoutButton = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.primary} !important;
  margin-left: 15px;
`;
