import React from "react";
import { Container, Wrapper, Loader, Text } from "./styles";

const LoadingModal = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Container loading={isLoading ? 1 : 0}>
        <Wrapper>
          <Loader />

          <Text>Please wait...</Text>
        </Wrapper>
      </Container>
    );
  }

  return null;
};

export default LoadingModal;
