import React, { useEffect } from "react";
import { useCookies } from "react-cookie";

import { useGetConfigQuery } from "../../store/settings/settingsService";

const SettingsProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(["_C2C_REFERER"]);

  const { referrer } = window.document;
  const { isLoading } = useGetConfigQuery({});

  useEffect(() => {
    const ref = window.document;

    setCookie("_C2C_REFERER", ref.referrer, {
      path: "/",
    });
  }, []);

  useEffect(() => {
    if (referrer) {
      setCookie("_C2C_REFERER", referrer, {
        path: "/",
      });
    }
  }, [referrer]);

  return children;
};

export default SettingsProvider;
