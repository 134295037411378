import React, { memo } from "react";

import TopSection from "./TopSection";
import TopMessage from "./TopMessage";
import BottomSection from "./BottomSection";
import { useAdminPermission } from "../../hooks/useAdminPermission";
import { useAuth } from "../../hooks/useAuth";
import { SILVER } from "../../constants/loyalty-level";

import { Container, Wrapper, BottomWrapper } from "./styles";

const Header = ({ hiddenMenu }) => {
  const { user } = useAuth();
  const isAdmin = useAdminPermission();

  return (
    <>
      {user?.id && user?.loyalty_level === SILVER && (
        <TopMessage />
      )}

      <Container $height={user?.id && !isAdmin} $hiddenMenu={hiddenMenu}>
        <Wrapper direction="column">
          <TopSection hiddenMenu={isAdmin || !hiddenMenu || !user?.id} />

          {(isAdmin || !hiddenMenu || !user?.id) && (
            <BottomWrapper>
              <BottomSection />
            </BottomWrapper>
          )}
        </Wrapper>
      </Container>
    </>
  );
};
export default memo(Header);
