import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.pallette.silver.secondary}` : "none"};
`;

export const Container = styled.div`
  margin: 12px 16px;
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    path {
      stroke: ${({ theme }) => theme.pallette.silver.primary};
    }
  }
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.blue.primary};
  margin-left: 15px;
`;
