import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

const GtmProvider = ({ children }) => {
  const gtmId = process.env.REACT_APP_GTM_ID;

  useEffect(() => {
    if (gtmId) {
      const tagManagerArgs = {
        gtmId,
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, [gtmId]);

  return children;
};

export default GtmProvider;
