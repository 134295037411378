import styled from "styled-components";

export const CustomLink = styled.span`
  color: ${({ theme }) => theme.pallette.blue.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 500px;
  display: block;

  span {
    color: ${({ theme }) => theme.pallette.blue.link};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  @media (max-width: 767px) {
    width: auto;
  }
`;
