import React from "react";

import { StyledModal } from "./styles";

const Modal = ({
  children,
  open,
  onCancel,
  title,
  loading,
  onOk,
  footer,
  width,
  ...rest
}) => (
  <StyledModal
    title={title}
    centered
    open={open}
    onCancel={onCancel}
    onOk={onOk}
    okButtonProps={{ loading }}
    footer={footer}
    width={width}
    {...rest}
  >
    {children}
  </StyledModal>
);

export default Modal;
