import React from "react";
import { ReactComponent as ConfigIconOutline } from "../../../../assets/images/config.svg";

import { Container, Wrapper, StyledButton } from "./styles";

const ActionButton = ({ onClick }) => (
  <Container>
    <Wrapper>
      <StyledButton onClick={onClick} icon={<ConfigIconOutline />} />
    </Wrapper>
  </Container>
);

export default ActionButton;
