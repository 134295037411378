export const AUTH_PATH = {
  LOGIN_TYPE: "auth/:type",
  LOGIN: "login",
  OAUTH_CALLBACK_GOOGLE: "oauth/google/callback",
  OAUTH_CALLBACK_FACEBOOK: "oauth/facebook/callback",
  OAUTH_CALLBACK: "callback",
  AUTH: "auth",
  ROOT: "/",
  CLOSED: "closed",
  CHANGE_PASSWORD: "change-password",
};

export const ROOT = "/";
export const FROM_TO_PATH = ":fromTo?";
