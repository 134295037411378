import React, { memo } from "react";

import AuthSocialForm from "../AuthSocialForm";

import { Container, Wrapper, Divider, Text } from "./styles";

const AuthSocial = ({ title }) => (
  <Container>
    <Wrapper>
      <Divider />

      <Text>or</Text>

      <Divider />
    </Wrapper>

    <AuthSocialForm title={title} />
  </Container>
);

export default memo(AuthSocial);
