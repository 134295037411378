import React, { memo } from "react";

import { Container, Title, SubTitle, Link } from "./styles";

const AuthTitle = ({ title, subTitle, titleLink, goTo }) => (
  <Container>
    <Title>{title}</Title>

    <SubTitle>
      {subTitle} <Link onClick={goTo}>{titleLink}</Link>
    </SubTitle>
  </Container>
);

export default memo(AuthTitle);
