import { BaseQueryParams } from "../baseQuery";

export const billingService = BaseQueryParams("billingService", [
  "PROFILE_BILLING",
]).injectEndpoints({
  endpoints: (builder) => ({
    generateBillingPage: builder.mutation({
      query: (body) => ({
        url: "/profile/balance-refill",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGenerateBillingPageMutation } = billingService;
