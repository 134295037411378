import React, { memo } from "react";
import { Controller } from "react-hook-form";

import { ReactComponent as IconInputError } from "../../../../assets/images/IconInputError.svg";

import { Container, Label, StyledInput, Error } from "./styles";

const AuthInput = ({ label, control, name, rules, error, ...rest }) => (
  <Container>
    <Label>{label}</Label>

    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => (
        <StyledInput
          suffix={error ? <IconInputError /> : <span />}
          status={error ? "error" : ""}
          placeholder={label}
          {...rest}
          {...field}
        />
      )}
    />

    {error && <Error>{error}</Error>}
  </Container>
);

export default memo(AuthInput);
