import React from "react";

import ModalFooter from "../ModalMacros/components/ModalFooter";
import { ModalMacros, Logo, Title, SubTitle, CartList, Carts } from "./styles";

const ModalCarts = ({
  open,
  onCancel,
  actionHandle,
  loading,
  title,
  carts,
  logo,
  description,
  children,
}) => (
  <ModalMacros
    onCancel={onCancel}
    open={open}
    onOk={actionHandle}
    loading={loading}
    footer={
      <ModalFooter
        onClose={onCancel}
        closeTitle="Cancel"
        onSubmit={actionHandle}
        submitTitle="Ok"
      />
    }
  >
    {logo && <Logo>{logo}</Logo>}
    {title && <Title>{title}</Title>}
    {description && <SubTitle $center={carts}>{description}</SubTitle>}
    {carts && (
      <Carts>
        {carts.map((item) => (
          <CartList key={item.value}>{item.label}</CartList>
        ))}
      </Carts>
    )}

    {children}
  </ModalMacros>
);

export default ModalCarts;
