import React, { memo } from "react";
import { Controller } from "react-hook-form";

import { termsLink, compliance } from "../../../../constants/link";
import AuthButton from "../AuthButton";
import { InputsRules } from "../../../../constants/auth";

import { Container, StyledCheckbox, Link, Error, Wrapper } from "./styles";

const AuthRegistrationFooter = ({
  onSubmit,
  isValid,
  isLoading,
  control,
  error,
}) => (
  <Container>
    <Wrapper>
      <Controller
        control={control}
        rules={{ required: InputsRules.required }}
        name="policy"
        render={({ field }) => (
          <StyledCheckbox $isError={error} {...field} checked={field.value}>
            By signing up, you agree to the Cart2Cart{" "}
            <Link href={termsLink} target="_blank">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link href={compliance} target="_blank">
              GDPR Compliance.
            </Link>
          </StyledCheckbox>
        )}
      />
      {error && <Error>{error}</Error>}
    </Wrapper>

    <AuthButton onSubmit={onSubmit} isLoading={isLoading} isValid={isValid}>
      Sign Up
    </AuthButton>
  </Container>
);

export default AuthRegistrationFooter;
