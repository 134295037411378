import React from "react";
import { Collapse } from "antd";

import { Container } from "./styles";

const TableAccordion = ({ items, ...rest }) => (
  <Container>
    <Collapse accordion items={items} {...rest} />
  </Container>
);

export default TableAccordion;
