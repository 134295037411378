import { BaseQueryParams } from "../baseQuery";

export const registrationFundsService = BaseQueryParams(
  "registrationFundsService",
  ["FUNDS"],
).injectEndpoints({
  endpoints: (builder) => ({
    getFundsList: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/configurations/registration-funds${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["FUNDS"],
    }),

    deleteFunds: builder.mutation({
      query: (id) => ({
        url: `/admin/configurations/registration-funds/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["FUNDS"],
    }),

    createFunds: builder.mutation({
      query: (body) => ({
        url: "/admin/configurations/registration-funds",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["FUNDS"],
    }),

    editFunds: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/configurations/registration-funds/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["FUNDS"],
    }),
  }),
});

export const {
  useGetFundsListQuery,
  useDeleteFundsMutation,
  useCreateFundsMutation,
  useEditFundsMutation,
} = registrationFundsService;
