import React, { memo } from "react";
import { StyledTooltip } from "./styles";

const Tooltip = ({
  children,
  color = "#ffffff",
  title,
  border = 2,
  maxWidth,
  ...rest
}) => (
  <StyledTooltip
    color={color}
    title={title}
    overlayStyle={{
      borderRadius: `${border}px`,
      maxWidth: `${maxWidth}px`,
    }}
    overlayInnerStyle={{
      borderRadius: `${border}px`,
      maxWidth: `${maxWidth}px`,
    }}
    {...rest}
  >
    {children}
  </StyledTooltip>
);

export default memo(Tooltip);
