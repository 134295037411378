import React, { memo } from "react";
import { StyledButton } from "./styles";

const AuthButton = ({ children, onSubmit, isValid, isLoading }) => (
  <StyledButton
    className="custom"
    onClick={onSubmit}
    type="default"
    size="large"
    disabled={!isValid}
    loading={isLoading}
  >
    {children}
  </StyledButton>
);

export default memo(AuthButton);
