import styled from "styled-components";
import { Input } from "antd";

export const Container = styled.div`
  width: 100%;
  max-width: 584px;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(Input)`
  height: 48px;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.pallette.silver.secondary};
  font-size: 16px;

  & input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.pallette.blue.primary};
  }

  & input::placeholder {
    color: ${({ theme }) => theme.pallette.silver.primary};
  }
`;

export const InputPassword = styled(Input.Password)`
  height: 48px;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.pallette.silver.secondary};
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.pallette.blue.primary};
`;

export const Error = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.red.error};
  margin-top: 8px;
`;
