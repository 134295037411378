/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import ModalConfig from "../ModalConfig";
import MainButton from "../MainButton";

import { Container, Wrapper } from "./styles";

const GlobalConfig = () => {
  const [openModal, setOpenModal] = useState(false);
  const defaultValue =
    localStorage.getItem("host_api") || process.env.REACT_APP_HOST_API;
  const defaultMirror =
    localStorage.getItem("mirror_host") || process.env.REACT_APP_BACK_MIRROR_HOST || window.location.host;

  const formState = useForm({
    mode: "all",
    defaultValues: { host_api: defaultValue, mirror_host: defaultMirror },
  });

  const onButton = () => {
    if (openModal !== undefined) {
      setOpenModal((prev) => !prev);
    }
  };

  const onClickAction = () => {
    onButton();
  };

  useEffect(() => {
    if (openModal) {
      formState.setValue("host_api", defaultValue);
      formState.setValue("mirror_host", defaultMirror);
    }
  }, [openModal]);

  return (
    <Container>
      <Wrapper active={process.env.REACT_APP_NODE_ENV === "develop" && 1}>
        <MainButton onClick={onButton} />

        <ModalConfig
          open={openModal}
          onCancel={onButton}
          formState={formState}
          onClickAction={onClickAction}
        />
      </Wrapper>
    </Container>
  );
};

export default GlobalConfig;
