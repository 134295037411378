import React, { memo } from "react";

import { Container, Wrapper, StyledButton } from "./styles";

const AccessFooter = ({ nextStep, nextStepTitle, disabledButton }) => (
  <Container>
    <Wrapper>
      <StyledButton onClick={nextStep} disabled={!disabledButton}>
        {nextStepTitle}
      </StyledButton>
    </Wrapper>
  </Container>
);

export default memo(AccessFooter);
