import { useNavigate } from "react-router";
import { useFormTo } from "./useFormTo";

import { USER_PATH } from "../router/path/user";
import { WIZARD_ROOT_PATH } from "../router/path/wizard";

export const useWizardSteperRouter = () => {
  const navigate = useNavigate();
  const { prefix } = useFormTo();

  const redirectToNewWizardStep = (id) =>
    navigate(`/${prefix && `${prefix}/`}${WIZARD_ROOT_PATH}/${id}`);
  const redirectToCreateMigration = () =>
    navigate(`/${prefix && `${prefix}/`}${USER_PATH.WIZARD}`);
  const redirectToNewMigration = () =>
    navigate(`/${prefix && `${prefix}/`}${USER_PATH.WIZARD}/create`);
  const redirectToEditWizardStep = (id) =>
    navigate(
      `/${prefix && `${prefix}/`}${WIZARD_ROOT_PATH}/select-source/${id}`,
    );
  const redirectToContinue = (id, step) =>
    navigate(`/${prefix && `${prefix}/`}${WIZARD_ROOT_PATH}/${step}/${id}`);

  return {
    redirectToNewWizardStep,
    redirectToCreateMigration,
    redirectToEditWizardStep,
    redirectToContinue,
    redirectToNewMigration,
  };
};
