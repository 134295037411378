import { BaseQueryParams } from "../baseQuery";

export const migrationsService = BaseQueryParams("migrations", [
  "MIGRATIONS_LIST",
  "MIGRATION_DETAILS",
  "ADMIN_MIGRATION",
  "ADMIN_DEMO_ENTITIES",
  "MIGRATION_DETAILS_ATTRIBUTES",
  "MIGRATION_INSURANCES",
  "PROPERTIES_MIGRATION",
]).injectEndpoints({
  endpoints: (builder) => ({
    getMigrationsList: builder.query({
      query: () => ({
        url: "/migrations",
        method: "GET",
      }),
    }),

    getMyMigrationsList: builder.query({
      query: ({ page, per_page }) => ({
        url: `/migrations/?${page ? `page=${page}` : ""}&per_page=${
          per_page ? `${per_page}` : "5"
        }&with_remote=true`,
        method: "GET",
      }),

      providesTags: () => ["MIGRATIONS_LIST"],
    }),

    getMigrationById: builder.query({
      query: ({ id }) => ({
        url: `/migrations/${id}/details`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["MIGRATION_DETAILS"],
    }),

    getPropertiesMigrationById: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/properties`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["PROPERTIES_MIGRATION"],
      invalidatesTags: () => ["MIGRATION_DETAILS", "MIGRATION_INSURANCES"],
    }),

    setPropertiesMigrationById: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/${id}/properties`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["PROPERTIES_MIGRATION"],
    }),

    getAdminMigrations: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["ADMIN_MIGRATION"],
    }),

    deleteAdminMigration: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["ADMIN_MIGRATION"],
    }),

    getMigrationOptionsById: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/options`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["MIGRATION_DETAILS"],
    }),

    updateMigrationOption: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/${id}/options`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["PROPERTIES_MIGRATION", "MIGRATION_DETAILS"],
    }),

    getAdminMigrationsDemoEntities: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/demo-entities`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["ADMIN_DEMO_ENTITIES"],
    }),

    deleteAdminMigrationDemoEntities: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/${id}/demo-entities`,
        method: "Delete",
      }),

      invalidatesTags: () => ["ADMIN_DEMO_ENTITIES", "MIGRATION_DETAILS"],
    }),

    killDemoProcess: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/${id}/stop`,
        method: "POST",
      }),
    }),

    getAdminMigrationsCSVEntities: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/demo-entities/csv`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    getAdminMigrationsFileEntities: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/demo-entities/file`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    getMigrationAttributesById: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/attributes`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["MIGRATION_DETAILS_ATTRIBUTES"],
    }),

    updateMigrationAttribute: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/${id}/attributes`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["MIGRATION_DETAILS_ATTRIBUTES"],
    }),

    recountMigrationEntities: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/recount`,
        method: "POST",
      }),

      invalidatesTags: () => ["MIGRATION_DETAILS", "PROPERTIES_MIGRATION"],
    }),

    getRestartMigrationById: builder.query({
      query: ({ id }) => ({
        url: `/migrations/${id}/restart-migration`,
        method: "POST",
      }),
    }),

    getMigrationLogs: builder.query({
      query: ({ id, page, filters }) => ({
        url: `/migrations/${id}/log/?${page ? `page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getMigrationIssues: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations/issues/migrations?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
    }),

    getMigrationIssuesStatus: builder.query({
      query: () => ({
        url: `/admin/migrations/issues/statuses`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    getMigrationInsurances: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/insurance`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["MIGRATION_INSURANCES"],
    }),

    updateMigrationInsurance: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/${id}/insurance`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["PROPERTIES_MIGRATION", "MIGRATION_INSURANCES"],
    }),

    getRestartOptions: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/restart/options`,
        method: "GET",
      }),
    }),

    restartMigrationOnAdmin: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/${id}/restart`,
        method: "POST",
        body,
      }),
    }),

    restartMigration: builder.mutation({
      query: ({ id, body }) => ({
        url: `/migrations/${id}/restart`,
        method: "POST",
        body,
      }),
    }),

    restartMigrationDetails: builder.query({
      query: ({ id }) => ({
        url: `/migrations/${id}/restart`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    getMigrationStatuses: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/statuses`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    patchMigrationStatus: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/${id}/statuses`,
        method: "PATCH",
        body,
      }),

      invalidatesTags: () => ["ADMIN_MIGRATION"],
    }),

    getMigrationEntitiesHistory: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/entities/history`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    recentMigration: builder.mutation({
      query: (id) => ({
        url: `/migrations/wizard/recent-data/${id}`,
        method: "POST",
      }),
    }),

    getAmazonFilesList: builder.query({
      query: ({ id }) => ({
        url: `/migrations/${id}/details/amazon-files`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    downloadAmazonFile: builder.query({
      query: ({ id, body }) => ({
        url: `/migrations/${id}/details/download-amazon-file`,
        method: "GET",
        params: body,
      }),

      keepUnusedDataFor: 0,
    }),

    getFetchOptions: builder.query({
      query: () => ({
        url: `/admin/migrations/fetch/options`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    migrationFetch: builder.mutation({
      query: (body) => ({
        url: `/admin/migrations/fetch`,
        method: "POST",
        body,
      }),
    }),

    getRemoteLink: builder.query({
      query: (id) => ({
        url: `/migrations/${id}/remote-link`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetMigrationsListQuery,
  useGetMigrationByIdQuery,
  useGetMyMigrationsListQuery,
  useGetAdminMigrationsQuery,
  useDeleteAdminMigrationMutation,
  useGetMigrationOptionsByIdQuery,
  useUpdateMigrationOptionMutation,
  useGetAdminMigrationsDemoEntitiesQuery,
  useDeleteAdminMigrationDemoEntitiesMutation,
  useGetAdminMigrationsCSVEntitiesQuery,
  useLazyGetAdminMigrationsCSVEntitiesQuery,
  useGetAdminMigrationsFileEntitiesQuery,
  useLazyGetAdminMigrationsFileEntitiesQuery,
  useGetMigrationAttributesByIdQuery,
  useUpdateMigrationAttributeMutation,
  useRecountMigrationEntitiesMutation,
  useGetRestartMigrationByIdQuery,
  useGetMigrationLogsQuery,
  useGetMigrationIssuesQuery,
  useGetMigrationIssuesStatusQuery,
  useGetMigrationInsurancesQuery,
  useUpdateMigrationInsuranceMutation,
  useGetRestartOptionsQuery,
  useRestartMigrationMutation,
  useRestartMigrationDetailsQuery,
  useRestartMigrationOnAdminMutation,
  useGetMigrationStatusesQuery,
  usePatchMigrationStatusMutation,
  useGetMigrationEntitiesHistoryQuery,
  useGetPropertiesMigrationByIdQuery,
  useKillDemoProcessMutation,
  useRecentMigrationMutation,
  useSetPropertiesMigrationByIdMutation,
  useGetAmazonFilesListQuery,
  useLazyDownloadAmazonFileQuery,
  useGetFetchOptionsQuery,
  useMigrationFetchMutation,
  useLazyGetRemoteLinkQuery,
} = migrationsService;
