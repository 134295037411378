import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.pallette.blue.link};
    }
  }

  svg:last-child:hover {
    g {
      opacity: 1;
    }
  }

  &&[disabled] {
    pointer-events: none;
    opacity: 0.7;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.pallette.blue.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const TooltipText = styled.span`
  color: ${({ theme }) => theme.pallette.silver.dark};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
