/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice } from "@reduxjs/toolkit";

import { wizardService } from "./wizardService";
import { WIZARD_STEP_PATH } from "../../router/path/wizard";
import { WIZARD_STEPS_ADMIN, WIZARD_STEPS_USER } from "../../constants/wizard";

const initialState = {
  steps: WIZARD_STEPS_USER,
  currentStep: 1,
  activeStepPath: WIZARD_STEP_PATH.SELECT_SOURCE_TARGET,
  accountSelection: { email: "" },
  sourceStore: { source_store_id: "" },
  sourceCart: { current_store_url: "", type: "bridge" },
  isLoading: false,
  notificationData: "",

  migrationSession: {
    source_store_id: "",
    source_current_store_url: "",
    source_type_connect: "",
    target_current_store_url: "",
    target_store_id: "",
    target_type_connect: "",
    bridge_url: "",
    migrationId: "",
    optionsPriceText: [],
    selectedOptions: [],
    sourceFields: null,
    targetFields: null,
    selectedEntities: [],
    allOptions: null,
    status: "",
    options_price_dollars: "",
    entities_count: 0,
    price_in_dollars: "",
    price_in_dollars_with_discount: "",
    coupon_code: "",
  },

  source_bridge_connect: {
    isConnect: false,
    isError: false,
    message: "",
  },

  target_bridge_connect: {
    isConnect: false,
    isError: false,
    message: "",
  },

  demoProgress: {
    entities: [],
    show_survey: false,
  },
};

export const wizardSlice = createSlice({
  name: "wizard",
  initialState,

  reducers: {
    setAdminSteps: (state) => {
      state.steps = WIZARD_STEPS_ADMIN;
    },

    resetMigrationSession: (state) => {
      state.accountSelection = { email: "" };
      state.migrationSession.source_store_id = "";
      state.migrationSession.source_current_store_url = "";
      state.migrationSession.source_type_connect = "";
      state.migrationSession.target_current_store_url = "";
      state.migrationSession.target_store_id = "";
      state.migrationSession.target_type_connect = "";
      state.migrationSession.bridge_url = "";
    },

    setNewStep: (state, action) => {
      state.currentStep = action.payload;
    },

    setAccountSelection: (state, action) => {
      state.accountSelection.email = action.payload;
    },

    setSourceStore: (state, action) => {
      state.sourceStore.source_store_id = action.payload;
    },

    setSourceCart: (state, action) => {
      state.sourceCart.current_store_url = action.payload;
    },

    setPrevStep: (state, action) => {
      if (state.steps?.length <= action.payload) {
        state.currentStep = action.payload;
      }
    },

    setNewPath: (state, action) => {
      state.activeStepPath = action.payload;
    },

    setSourceConnect: (state, action) => {
      state.migrationSession.source_type_connect = action.payload;
    },

    setTargetConnect: (state, action) => {
      state.migrationSession.target_type_connect = action.payload;
    },

    setSourceUrl: (state, action) => {
      state.migrationSession.source_current_store_url = action.payload;
    },

    setTargetUrl: (state, action) => {
      state.migrationSession.target_current_store_url = action.payload;
    },

    setNotification: (state, action) => {
      state.notificationData = action.payload;
    },

    setBridgeConnectError: (
      { source_bridge_connect, target_bridge_connect },
      action,
    ) => {
      if (action.payload.type === "source-store") {
        source_bridge_connect.isError = true;
        source_bridge_connect.isConnect = false;
        source_bridge_connect.message = action.payload.text;
      }

      if (action.payload.type === "target-store") {
        target_bridge_connect.isError = true;
        target_bridge_connect.isConnect = false;
        target_bridge_connect.message = action.payload.text;
      }
    },

    setBridgeConnectSuccess: (
      { source_bridge_connect, target_bridge_connect },
      action,
    ) => {
      if (action.payload.type === "source-store") {
        source_bridge_connect.isError = false;
        source_bridge_connect.isConnect = true;
        source_bridge_connect.message = action.payload.text;
      }

      if (action.payload.type === "target-store") {
        target_bridge_connect.isError = false;
        target_bridge_connect.isConnect = true;
        target_bridge_connect.message = action.payload.text;
      }
    },

    resetBridgeConnect: ({ source_bridge_connect, target_bridge_connect }) => {
      source_bridge_connect.isError = false;
      source_bridge_connect.isConnect = false;
      source_bridge_connect.message = "";

      target_bridge_connect.isError = false;
      target_bridge_connect.isConnect = false;
      target_bridge_connect.message = "";
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      wizardService.endpoints.checkMigrationStatus.matchFulfilled,
      ({ migrationSession }, { payload }) => {
        migrationSession.status = payload?.payload?.status;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getMigrationSession.matchFulfilled,
      ({ migrationSession }, { payload }) => {
        migrationSession.migrationId = payload?.payload?.id;
        migrationSession.optionsPriceText = Object.keys(
          payload?.payload?.options_price_text || {},
        );
        migrationSession.selectedOptions = Object.values(
          payload?.payload?.options_price_text || {},
        );
        migrationSession.status = payload?.payload?.status;
        migrationSession.options_price_dollars =
          payload?.payload?.options_price_dollars;
        migrationSession.entities_count = payload?.payload?.entities_count;
        migrationSession.price_in_dollars = payload?.payload?.price_in_dollars;
        (migrationSession.price_in_dollars_with_discount =
          payload?.payload?.price_in_dollars_with_discount),
          (migrationSession.coupon_code = payload?.payload?.coupon_code);

        if (payload?.payload?.entities?.length) {
          migrationSession.selectedEntities = payload?.payload?.entities;
        } else {
          migrationSession.selectedEntities = [];
        }

        if (payload?.payload?.source_store?.id) {
          migrationSession.source_store_id =
            payload?.payload?.source_store?.cart || "";
          migrationSession.source_current_store_url =
            payload?.payload?.source_store?.url || "";
        }

        if (payload?.payload?.target_store?.id) {
          migrationSession.target_current_store_url =
            payload?.payload?.target_store?.url || "";
          migrationSession.target_store_id =
            payload?.payload?.target_store?.cart || "";
        }
      },
    );

    builder.addMatcher(
      wizardService.endpoints.checkTypeConnect.matchFulfilled,
      ({ migrationSession }, { payload }) => {
        if (payload?.payload?.storetype === "source") {
          migrationSession.source_type_connect =
            payload?.payload?.cartMigrationType;
        }

        if (payload?.payload?.storetype === "target") {
          migrationSession.target_type_connect =
            payload?.payload?.cartMigrationType;
        }

        if (payload?.payload?.fields?.source) {
          migrationSession.sourceFields = payload?.payload?.fields?.source;
        }

        if (payload?.payload?.fields?.target) {
          migrationSession.targetFields = payload?.payload?.fields?.target;
        }
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getDemoProgressEntities.matchFulfilled,
      ({ demoProgress }, { payload }) => {
        demoProgress.entities = Object.keys(
          payload?.payload?.entities || {},
        )?.map((item) => ({
          name: item,
          percent: 100,
        }));
      },
    );

    builder.addMatcher(
      wizardService.endpoints.downloadBridge.matchFulfilled,
      ({ migrationSession }, { payload }) => {
        if (payload?.payload?.url) {
          migrationSession.bridge_url = payload?.payload?.url;
        }
      },
    );

    builder.addMatcher(
      wizardService.endpoints.connectSourceBridge.matchFulfilled,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getOptions.matchFulfilled,
      ({ migrationSession }, payload) => {
        if (
          Object.keys(payload?.payload?.payload?.["data-notices"] || {})?.length
        ) {
          migrationSession.allOptions =
            payload?.payload?.payload?.["data-notices"];
        } else {
          migrationSession.allOptions = null;
        }
      },
    );

    builder.addMatcher(
      wizardService.endpoints.connectSourceBridge.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.connectionApiStore.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.editMigrationSession.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.editMigrationSession.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.connectSourceBridge.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getMigrationSession.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getMigrationSession.matchFulfilled,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getMigrationSession.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.startDemo.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.startDemo.matchFulfilled,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.startDemo.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getSurvey.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getSurvey.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      wizardService.endpoints.getSurvey.matchFulfilled,
      (state) => {
        state.isLoading = false;
      },
    );
  },
});

export const {
  setAdminSteps,
  setNewStep,
  setPrevStep,
  setNewPath,
  setAccountSelection,
  setSourceStore,
  setSourceCart,
  setSourceConnect,
  setSourceUrl,
  setBridgeConnectError,
  setBridgeConnectSuccess,
  resetBridgeConnect,
  resetMigrationSession,
  setTargetConnect,
  setTargetUrl,
  setNotification,
} = wizardSlice.actions;

export const selectSteps = (state) => state.wizard.steps;
export const selectWizardState = (state) => state.wizard;
export const selectCurrentSession = (state) => state.wizard.migrationSession;
export const selectSourceBridgeConnect = (state) =>
  state.wizard.source_bridge_connect;
export const selectTargetBridgeConnect = (state) =>
  state.wizard.target_bridge_connect;
export const selectLoading = (state) => state.wizard;
export const selectDemoProgress = (state) => state.wizard.demoProgress;
export const selectNotification = (state) => state.wizard.notificationData;

export default wizardSlice.reducer;
