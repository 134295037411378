import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: #101828;
`;

export const SubTitle = styled.div`
  max-width: 900px;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme?.pallette.silver?.primary};
`;
