import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`;

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.06),
    0 2px 32px 0 rgba(0, 0, 0, 0.16);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  box-sizing: content-box;
  overflow: hidden;

  &&:hover {
    transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
    transform: scale(1.1);
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.pallette.white.main};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  background: ${({ theme }) => theme.pallette.blue.light};
  padding: 0;
  margin: 0;
  border: none;

  &.ant-btn-default {
    &:not(:disabled):not(.ant-btn-disabled).ant-btn-icon-only {
      width: 100%;
    }
  }

  svg {
    background-color: ${({ theme }) => theme.pallette.blue.light};

    g path {
      fill: ${({ theme }) => theme.pallette.white.main};
    }
  }
`;

export const TextButton = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.silver.primary};
  margin-top: 8px;
`;
