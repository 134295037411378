import { createSlice } from "@reduxjs/toolkit";
import { keys } from "lodash";

// import { CELL_ARRAY } from "@models/constants/cell_types";
import { configurationService } from "./configurationService";

const initialState = {
  selected: null,
  payload: null,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,

  extraReducers: (builder) => {
    builder.addMatcher(
      configurationService.endpoints.getConfigurationGeneral.matchFulfilled,
      (state, { payload }) => {
        const dataset = payload?.payload || [];

        const updatePayload = keys(dataset).map((name, key) => {
          const children = dataset[name];
          (children || []).forEach((props) => {
            const { field_type, value } = props;
            // if (field_type === CELL_ARRAY)
            //   props.value = JSON.stringify(value || []);
          });

          return {
            key: name,
            name: !children ? name : null,
            children,
          };
        });

        state.payload = updatePayload;
      },
    );
  },
});

export default configurationSlice.reducer;

export const selectConfiguration = (state) => state.configuration;
