/* eslint-disable no-nested-ternary */
import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.pallette.blue.main};
  padding: 12px 10px;
  position: sticky;
  top: 0;
  height: ${({ $height, $hiddenMenu }) =>
    $height ? ($hiddenMenu ? "64px" : "135px") : "109px"};
  z-index: 99;

  @media (max-width: 900px) {
    padding: 12px 20px;
  }

  @media (max-width: 767px) {
    height: 77px;
  }

  @media (max-width: 650px) {
    padding: 12px 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1710px) {
    max-width: calc(100% - 226px);
  }

  @media (max-width: 1580px) {
    max-width: calc(100% - 50px);
  }

  @media (max-width: 1303px) {
    max-width: 1200px;
  }

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;

  @media (max-width: 767px) {
    display: none;
  }
`;
