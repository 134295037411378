import styled from "styled-components";
import { Avatar } from "antd";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.pallette.green.light};
  width: 32px;
  height: 32px;
`;

export const UserName = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.pallette.white.main};
  width: 95px;
  overflow: hidden;
  height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ContainerIcon = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.pallette.blue.link};
      }
    }
  }
`;
