import styled from "styled-components";

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1710px) {
    max-width: calc(100% - 226px);
  }

  @media (max-width: 1580px) {
    max-width: calc(100% - 50px);
  }

  @media (max-width: 1303px) {
    max-width: 1200px;
  }

  @media (max-width: 767px) {
    border-top: 1px solid ${({ theme }) => theme.pallette.silver.secondary};
    padding-top: 24px;
  }
`;
