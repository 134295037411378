export const NO_AUTH_PUBLIC_LINK = [
  {
    title: "Home",
    link: "https://staging.public.shopping-cart-migration.com",
  },
  {
    title: "Supported Carts",
    link: "https://staging.public.shopping-cart-migration.com/supported-carts",
  },
  {
    title: "Services",
    link: "https://staging.public.shopping-cart-migration.com/provided-services",
  },
  {
    title: "Contact Us",
    link: "https://staging.public.shopping-cart-migration.com/contact-us",
  },
];
