export const registrationQuizTypeArr = [
  "startRegistrationQuiz",
  "finishFirstRegistrationQuiz",
  "finishSecondRegistrationQuiz",
];

export const startAnswersArray = [
  {
    label: "Store owner / Agency",
    type: "store-agency",
  },
  {
    label: "Hosting / Vendor / Partner",
    type: "hosting-vendor-partner",
  },
];

export const finishFirstAnswersArray = [
  {
    label: "Store owner",
    type: "store-owner",
  },
  {
    label: "Agency",
    type: "agency",
  },
  {
    label: "Freelancer",
    type: "freelancer",
  },
];

export const finishSecondAnswersArray = [
  {
    label: "Hosting",
    type: "hosting",
  },
  {
    label: "Vendor",
    type: "vendor",
  },
  {
    label: "Affiliate",
    type: "affiliate",
  },
  {
    label: "Directory",
    type: "directory",
  },
];

export const registrationQuizTypeElem = {
  startRegistrationQuiz: {
    headerTitle: "What's your role?",
    answers: startAnswersArray,
  },
  finishFirstRegistrationQuiz: {
    headerTitle: "What's your role?",
    answers: finishFirstAnswersArray,
  },
  finishSecondRegistrationQuiz: {
    headerTitle: "What's your role?",
    answers: finishSecondAnswersArray,
  },
};

export const roleTypeIndexMap = {
  "store-agency": 1,
  "hosting-vendor-partner": 2,
};
