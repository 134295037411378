import { BaseQueryParams } from "../baseQuery";

export const migrationsServicesService = BaseQueryParams(
  "migrationsServicesService",
).injectEndpoints({
  endpoints: (builder) => ({
    getServicesOptions: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations/service/options?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getServicesInsurances: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations-service/insurances?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getServicesRecentData: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations-service/recent?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getServicesBlogs: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/reports/migrations-service/blogs?${
          page ? `page=${page}` : ""
        }&per_page=${per_page ? `${per_page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),
    }),
  }),
});

export const {
  useGetServicesOptionsQuery,
  useGetServicesInsurancesQuery,
  useGetServicesRecentDataQuery,
  useGetServicesBlogsQuery,
} = migrationsServicesService;
