import React, { memo } from "react";

import Package from "../../../../package.json";

import { WrapperBottom, Copyright, Version } from "./styles";

const BottomSection = () => {
  const currentYear = new Date().getFullYear();

  return (
    <WrapperBottom>
      <Copyright> © 2008-{currentYear}</Copyright>

      <Version>
        Service version: {Package.version} {process.env.REACT_APP_NODE_ENV}
      </Version>
    </WrapperBottom>
  );
};

export default memo(BottomSection);
