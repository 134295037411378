import React from "react";

import { Container, Label, StyledRadio, IconBlock } from "./styles";

const RadioCart = ({
  onChangeRate,
  type,
  icon,
  label,
  name,
  selectedValue,
  modalType,
  error,
}) => {
  const onClick = () => onChangeRate(type);

  return (
    <Container
      $modalType={modalType}
      onClick={onClick}
      active={selectedValue === type}
      error={error}
    >
      <IconBlock>{icon}</IconBlock>

      <Label $modalType={modalType} error={error}>
        {label}
      </Label>

      <StyledRadio
        onClick={onClick}
        value={type}
        name={name}
        checked={selectedValue === type}
      />
    </Container>
  );
};

export default RadioCart;
