import React, { memo, useState } from "react";

import { ReactComponent as FeaturedNewTools } from "../../../assets/images/FeaturedNewTools.svg";

import { Container, Wrapper, StyledLink } from "./styles";
import ModalCarts from "../../ModalCarts";

const TopMessage = () => {
  const [openModal, setOpenModal] = useState(false);
  const onCloseModal = () => setOpenModal((prev) => !prev);
  const handleClick = () => {
    onCloseModal();
  };

  return (
    <>
      <Container>
        <Wrapper>
          You are currently on the beta version of our brand-new migration tool,
          you will get a hot &#128293; 30% discount for regular migrations above 300$ or
          free migration below 300$.
          <StyledLink onClick={handleClick}>Learn more</StyledLink>
        </Wrapper>
      </Container>

      {openModal && (
        <ModalCarts
          open={openModal}
          title="New migration tool"
          description="We have created from scratch our brand new migration tool, it’s currently on the beta stage. We opened it for our loyal customers, and give discount for regular automatic migrations (it doesn’t include packages)."
          logo={<FeaturedNewTools />}
          onCancel={onCloseModal}
        />
      )}
    </>
  );
};

export default memo(TopMessage);
