import React from "react";

import DynamicSvgActions from "../../../UI/DynamicSvgActions";
import { ReactComponent as SocialTableIcon } from "../../../../assets/images/SocialTableIcon.svg";
import Tooltip from "../../../UI/Tooltip";

import { Container, Title, TooltipText } from "./styles";

const HeaderAction = ({
  type,
  title,
  redirectByType,
  isIcon,
  tooltipTitle,
  csvLoading,
  status
}) => {
  const onClick = () => redirectByType(type);

  return (
    <Container onClick={onClick} disabled={(type === "exportCSV" && csvLoading) || (type === "killDemoProcess" && status !== 'in_demo_progress')}>
      <DynamicSvgActions imageName={type} />

      <Title>{title}</Title>

      {isIcon && (
        <Tooltip
          color="#0A1338"
          title={<TooltipText>{tooltipTitle}</TooltipText>}
          placement="bottom"
          border={8}
        >
          <SocialTableIcon />
        </Tooltip>
      )}
    </Container>
  );
};

export default HeaderAction;
