import styled from "styled-components";

export const CustomLink = styled.span`
  color: ${({ theme }) => theme.pallette.blue.link};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
