/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { Radio } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ $modalType }) =>
    $modalType ? "row-reverse" : "column"};
  justify-content: space-between;
  align-items: center;
  padding: ${({ $modalType }) => ($modalType ? "10px" : "24px")};
  width: ${({ $modalType }) => ($modalType ? "100%" : "260px")};
  background: ${({ theme }) => theme.pallette.white.main};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
  border: 2px solid ${({ theme }) => theme.pallette.silver.secondary};
  text-align: center;
  border-color: ${({ active, error, theme }) =>
    active
      ? theme.pallette.blue.link
      : error
      ? theme.pallette.red.light
      : theme.pallette.silver.secondary};
  border-width: ${({ active }) => (active ? "2px" : "1px")};

  &:hover {
    border-color: ${({ active, theme }) =>
      active ? theme.pallette.blue.link : "#47fe75"};
    background: ${({ $modalType }) =>
      $modalType ? "rgba(71, 254, 117, 0.1)" : "white"};
  }

  & > span {
    padding: 16px 0;
  }

  @media (max-width: 1024px) {
    width: ${({ $modalType }) => ($modalType ? "100%" : "161px")};
    width: ${({ $modalType }) => ($modalType ? "auto" : "120px")};
    padding: 16px 8px;

    & > span {
      padding-top: 4px;
      padding-bottom: 8px;
    }
  }
`;

export const Label = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ $modalType }) => ($modalType ? "600" : "400")};
  line-height: 24px;
  color: ${({ error, theme }) =>
    error ? theme.pallette.red.error : theme.pallette.blue.primary};
`;

export const StyledRadio = styled(Radio)`
  color: ${({ theme }) => theme.pallette.blue.link};
  text-decoration: underline;
  margin: 0 3px;
`;

export const IconBlock = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;
