import { BaseQueryParams } from "../baseQuery";

export const settingsService = BaseQueryParams("config").injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: () => ({
        url: "/config/public",
        method: "GET",
      }),
    }),

    moduleShopify: builder.mutation({
      query: ({ body, from = null, to = null }) => ({
        url: `/wizard/modules/from-${from ?? 0}-to-${to ?? 0}/shopify`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["SURVEY"],
    }),
  }),
});

export const { useGetConfigQuery, useModuleShopifyMutation } = settingsService;
