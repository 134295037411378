import Aabaco from "../assets/images/platform/aabaco.svg";
import Ablecommerce from "../assets/images/platform/ablecommerce.svg";
import Aceshop from "../assets/images/platform/aceshop.svg";
import Actinic from "../assets/images/platform/actinic.svg";
import Amazon from "../assets/images/platform/amazon.svg";
import Americommerce from "../assets/images/platform/americommerce.svg";
import Arastta from "../assets/images/platform/arastta.svg";
import Aspdotnetstorefront from "../assets/images/platform/aspdotnetstorefront.svg";
import Bigcartel from "../assets/images/platform/bigcartel.svg";
import Bigcommerce from "../assets/images/platform/bigcommerce.svg";
import Cart66 from "../assets/images/platform/cart66.svg";
import Carttocsv from "../assets/images/platform/carttocsv.svg";
import Channeladvisor from "../assets/images/platform/channeladvisor.svg";
import Cloud from "../assets/images/platform/cloud.svg";
import Corecommerce from "../assets/images/platform/corecommerce.svg";
import Creloaded from "../assets/images/platform/creloaded.svg";
import Cscart from "../assets/images/platform/cscart.svg";
import Csvtocart from "../assets/images/platform/csvtocart.svg";
import Cubecart from "../assets/images/platform/cubecart.svg";
import Customcart from "../assets/images/platform/customcart.svg";
import Dawanda from "../assets/images/platform/dawanda.svg";
import Default from "../assets/images/platform/default.svg";
import Digishop from "../assets/images/platform/digishop.svg";
import Drupalcommerce from "../assets/images/platform/drupalcommerce.svg";
import Easydigitaldownloads from "../assets/images/platform/easydigitaldownloads.svg";
import Ecwid from "../assets/images/platform/ecwid.svg";
import Ekmpowershop from "../assets/images/platform/ekmpowershop.svg";
import Entity3dcart from "../assets/images/platform/entity3dcart.svg";
import Epages from "../assets/images/platform/epages.svg";
import Etsy from "../assets/images/platform/etsy.svg";
import File2cart from "../assets/images/platform/file2cart.svg";
import Gambio from "../assets/images/platform/gambio.svg";
import Godaddy from "../assets/images/platform/godaddy.svg";
import Hhgmultistore from "../assets/images/platform/hhgmultistore.svg";
import Hikashop from "../assets/images/platform/hikashop.svg";
import Interspire from "../assets/images/platform/interspire.svg";
import Jigoshop from "../assets/images/platform/jigoshop.svg";
import Joocart from "../assets/images/platform/joocart.svg";
import Joomshopping from "../assets/images/platform/joomshopping.svg";
import Lemonstand from "../assets/images/platform/lemonstand.svg";
import Lemonstandapi from "../assets/images/platform/lemonstandapi.svg";
import Lightspeed from "../assets/images/platform/lightspeed.svg";
import Litecart from "../assets/images/platform/litecart.svg";
import Loaded7 from "../assets/images/platform/loaded7.svg";
import Loadedcommerce from "../assets/images/platform/loadedcommerce.svg";
import Magento from "../assets/images/platform/magento.svg";
import Merchium from "../assets/images/platform/merchium.svg";
import Mijoshop from "../assets/images/platform/mijoshop.svg";
import Miva from "../assets/images/platform/miva.svg";
import Miva5 from "../assets/images/platform/miva5.svg";
import Miwoshop from "../assets/images/platform/miwoshop.svg";
import Modified from "../assets/images/platform/modified.svg";
import Mystoreno from "../assets/images/platform/mystoreno.svg";
import Neto from "../assets/images/platform/neto.svg";
import Networksolutions from "../assets/images/platform/networksolutions.svg";
import Nopcommerce from "../assets/images/platform/nopcommerce.svg";
import Opencart from "../assets/images/platform/opencart.svg";
import Oscmax2 from "../assets/images/platform/oscmax2.svg";
import Oscommerce22ms2 from "../assets/images/platform/oscommerce22ms2.svg";
import Oxid from "../assets/images/platform/oxid.svg";
import Palundu from "../assets/images/platform/palundu.svg";
import Pinnacle from "../assets/images/platform/pinnacle.svg";
import Play from "../assets/images/platform/play.svg";
import Premmerce from "../assets/images/platform/premmerce.svg";
import Prestasho1 from "../assets/images/platform/prestashop-1.svg";
import Prestashop from "../assets/images/platform/prestashop.svg";
import Saphybris from "../assets/images/platform/saphybris.svg";
import Shift4shop from "../assets/images/platform/shift4shop.svg";
import Shopify from "../assets/images/platform/shopify.svg";
import Shopp from "../assets/images/platform/shopp.svg";
import Shopscript from "../assets/images/platform/shopscript.svg";
import Shopsite from "../assets/images/platform/shopsite.svg";
import Shoptab from "../assets/images/platform/shoptab.svg";
import Shopware from "../assets/images/platform/shopware.svg";
import Shopwareapi from "../assets/images/platform/shopwareapi.svg";
import Sparkpay from "../assets/images/platform/sparkpay.svg";
import Squarespace from "../assets/images/platform/squarespace.svg";
import Squirrelcart from "../assets/images/platform/squirrelcart.svg";
import Storeden from "../assets/images/platform/storeden.svg";
import Summercart from "../assets/images/platform/summercart.svg";
import Sun from "../assets/images/platform/sun.svg";
import Sunshop from "../assets/images/platform/sunshop.svg";
import Thirtybees from "../assets/images/platform/thirtybees.svg";
import Tomatocart from "../assets/images/platform/tomatocart.svg";
import Ubercart from "../assets/images/platform/ubercart.svg";
import Virtuemart from "../assets/images/platform/virtuemart.svg";
import Visualsoft from "../assets/images/platform/visualsoft.svg";
import Volusion from "../assets/images/platform/volusion.svg";
import Weebly from "../assets/images/platform/weebly.svg";
import Wix from "../assets/images/platform/wix.svg";
import Wizishop from "../assets/images/platform/wizishop.svg";
import Woocommerce from "../assets/images/platform/woocommerce.svg";
import Wpecommerce from "../assets/images/platform/wpecommerce.svg";
import Wpestoreplugin from "../assets/images/platform/wpestoreplugin.svg";
import Xcart from "../assets/images/platform/xcart.svg";
import Xtcommerce from "../assets/images/platform/xtcommerce.svg";
import Xtcommercenext from "../assets/images/platform/xtcommercenext.svg";
import Yahoostore from "../assets/images/platform/yahoostore.svg";
import Yahoostorecsv from "../assets/images/platform/yahoostorecsv.svg";
import Zencart137 from "../assets/images/platform/zencart137.svg";
import Zoey from "../assets/images/platform/zoey.svg";

const ff = {};

export const STORE_IMAGES = {
  aabaco: Aabaco,
  ablecommerce: Ablecommerce,
  aceshop: Aceshop,
  actinic: Actinic,
  amazon: Amazon,
  americommerce: Americommerce,
  arastta: Arastta,
  aspdotnetstorefront: Aspdotnetstorefront,
  bigcartel: Bigcartel,
  bigcommerce: Bigcommerce,
  cart66: Cart66,
  carttocsv: Carttocsv,
  channeladvisor: Channeladvisor,
  cloud: Cloud,
  corecommerce: Corecommerce,
  creloaded: Creloaded,
  cscart: Cscart,
  csvtocart: Csvtocart,
  cubecart: Cubecart,
  customcart: Customcart,
  dawanda: Dawanda,
  default: Default,
  digishop: Digishop,
  drupalcommerce: Drupalcommerce,
  easydigitaldownloads: Easydigitaldownloads,
  ecwid: Ecwid,
  ekmpowershop: Ekmpowershop,
  entity3dcart: Entity3dcart,
  entity3dcartsoap: Entity3dcart,
  epages: Epages,
  etsy: Etsy,
  file2cart: File2cart,
  gambio: Gambio,
  godaddy: Godaddy,
  hhgmultistore: Hhgmultistore,
  hikashop: Hikashop,
  interspire: Interspire,
  jigoshop: Jigoshop,
  joocart: Joocart,
  joomshopping: Joomshopping,
  lemonstand: Lemonstand,
  lemonstandapi: Lemonstandapi,
  lightspeed: Lightspeed,
  litecart: Litecart,
  loaded7: Loaded7,
  loadedcommerce: Loadedcommerce,
  magento: Magento,
  merchium: Merchium,
  mijoshop: Mijoshop,
  miva: Miva,
  miva5: Miva5,
  miwoshop: Miwoshop,
  modified: Modified,
  mystoreno: Mystoreno,
  neto: Neto,
  networksolutions: Networksolutions,
  nopcommerce: Nopcommerce,
  opencart: Opencart,
  oscmax2: Oscmax2,
  oscommerce22ms2: Oscommerce22ms2,
  oxid: Oxid,
  palundu: Palundu,
  pinnacle: Pinnacle,
  play: Play,
  premmerce: Premmerce,
  "prestashop-1": Prestasho1,
  prestashop: Prestashop,
  saphybris: Saphybris,
  shift4shop: Shift4shop,
  shopify: Shopify,
  shopp: Shopp,
  shopscript: Shopscript,
  shopsite: Shopsite,
  shoptab: Shoptab,
  shopware: Shopware,
  shopwareapi: Shopwareapi,
  sparkpay: Sparkpay,
  squarespace: Squarespace,
  squirrelcart: Squirrelcart,
  storeden: Storeden,
  summercart: Summercart,
  sun: Sun,
  sunshop: Sunshop,
  thirtybees: Thirtybees,
  tomatocart: Tomatocart,
  ubercart: Ubercart,
  virtuemart: Virtuemart,
  visualsoft: Visualsoft,
  volusion: Volusion,
  weebly: Weebly,
  wix: Wix,
  wizishop: Wizishop,
  woocommerce: Woocommerce,
  wpecommerce: Wpecommerce,
  wpestoreplugin: Wpestoreplugin,
  xcart: Xcart,
  xtcommerce: Xtcommerce,
  xtcommercenext: Xtcommercenext,
  yahoostore: Yahoostore,
  yahoostorecsv: Yahoostorecsv,
  zencart137: Zencart137,
  zoey: Zoey,
};
