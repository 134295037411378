import { BaseQueryParams } from "../baseQuery";

export const configPackagesService = BaseQueryParams("configPackagesService", [
  "CONFIG_PACKAGE",
  "CONFIG_PRICE_PACKAGE",
]).injectEndpoints({
  endpoints: (builder) => ({
    getPackages: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/migrations/packages${page ? `?page=${page}` : ""}${
          per_page ? `&per_page=${per_page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
      providesTags: () => ["CONFIG_PACKAGE"],
    }),

    createPackage: builder.mutation({
      query: (body) => ({
        url: "/admin/migrations/package",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["CONFIG_PACKAGE"],
    }),

    editPackage: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/packages/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["CONFIG_PACKAGE"],
    }),

    getPricePackages: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations/package-price${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
      providesTags: () => ["CONFIG_PRICE_PACKAGE"],
    }),

    createPricePackage: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/package-price/${id}`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["CONFIG_PRICE_PACKAGE"],
    }),

    editPricePackage: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/package-price/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["CONFIG_PRICE_PACKAGE"],
    }),

    getPackagesConfig: builder.query({
      query: () => ({
        url: `/admin/migrations/price-rules/config`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useEditPackageMutation,
  useCreatePackageMutation,
  useGetPricePackagesQuery,
  useEditPricePackageMutation,
  useCreatePricePackageMutation,
  useGetPackagesConfigQuery,
} = configPackagesService;
