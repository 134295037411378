import React from "react";
import { StyledButton } from "./styles";

const ModalButton = ({ onClick, children, ...rest }) => (
  <StyledButton type="default" onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

export default ModalButton;
