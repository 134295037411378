import React, { useEffect } from "react";

import {
  AFFILIATE_CLICK_SCRIPT_URL,
  AFFILIATE_SALE_SCRIPT_URL,
} from "../../constants/affiliate-url";

const AffiliateProvider = ({ children }) => {
  const affiliateId = process.env.REACT_APP_AFFILIATE_ID;

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.id = affiliateId;
    script.src = AFFILIATE_CLICK_SCRIPT_URL;
    script.type = "text/javascript";

    script.onerror = () => {
      console.error("Affiliate script error");
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.id = affiliateId;
    script.src = AFFILIATE_SALE_SCRIPT_URL;
    script.type = "text/javascript";

    script.onerror = () => {
      console.error("Affiliate script error");
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return children;
};

export default AffiliateProvider;
