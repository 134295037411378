import React, { memo } from "react";
import { useAuth } from "../../../hooks/useAuth";

import HeaderNavigation from "../HeaderNavigation";
import UserNavigation from "../UserNavigation";

import { Container } from "./styles";

const BottomSection = () => {
  const { user } = useAuth();

  return (
    <Container>
      {user?.id ? <UserNavigation /> : <HeaderNavigation />}
    </Container>
  );
};

export default memo(BottomSection);
