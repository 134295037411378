import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAdminPermission } from "../../../hooks/useAdminPermission";
import { selectSettings } from "../../../store/settings/settingsSlice";
import { linksByPermission } from "../../../constants/footer";
import DropdownMenu from "../../UI/DropdownMenu";
import FooterAccordion from "../FooterAccordion";

import { Container, DropdownWrapper, CollapseWrapper } from "./styles";

const FooterDropdownLink = () => {
  const { publicUrl } = useSelector(selectSettings);
  const isAdmin = useAdminPermission();

  const links = useMemo(
    () => linksByPermission(isAdmin, publicUrl),
    [isAdmin, publicUrl],
  );

  return (
    <Container>
      <DropdownWrapper>
        {links?.map((link) => (
          <DropdownMenu
            key={link.title}
            title={link.title}
            items={link?.items}
          />
        ))}
      </DropdownWrapper>

      <CollapseWrapper>
        <FooterAccordion items={links} />
      </CollapseWrapper>
    </Container>
  );
};

export default memo(FooterDropdownLink);
