import React, { Suspense, useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";

import Footer from "../../Footer";
import Header from "../../Header";
import { getScrollPage } from "../../../utils";

import { StyledMain, StyledContainer } from "./styles";

const LazyLayout = ({ offLayout }) => {
  const [hiddenMenu, setHiddenMenu] = useState(false);
  const layoutRef = useRef(null);

  useEffect(() => {
    getScrollPage(layoutRef, setHiddenMenu);
  }, []);

  return (
    <>
      {!offLayout && (
        <>
          <Header hiddenMenu={hiddenMenu} />

          <StyledContainer ref={layoutRef}>
            <StyledMain>
              <Suspense fallback={<h1>Loading ...</h1>}>
                <Outlet />
              </Suspense>
            </StyledMain>

            <Footer />
          </StyledContainer>
        </>
      )}

      {offLayout && (
        <Suspense fallback={<h1>Loading ...</h1>}>
          <Outlet />
        </Suspense>
      )}
    </>
  );
};

export default LazyLayout;
