import { useMemo } from "react";
import { useSelector } from "react-redux";

import { hasRequiredPermissions } from "../utils";
import { selectCurrentUser } from "../store/authentication/authService";
import { ADMIN_PERMISSIONS } from "../constants/permissions";

export const useAdminPermission = () => {
  const { user } = useSelector(selectCurrentUser);

  return useMemo(
    () => hasRequiredPermissions(user?.abilities, ADMIN_PERMISSIONS),
    [user?.abilities],
  );
};
