import React from "react";

import { CustomMessage } from "./styles";
import SetNewStore from "../SetNewStore";
import DefaultMap from "../DefaultMap";
import BridgeManualDownload from "../BridgeManualDownload";

const ErrorMapContent = ({
  error,
  variables,
  dangerouslySetInnerHTML,
  onClose,
}) => {
  const { click_event_name } = variables?.link || {};

  const renderContent = () => {
    switch (click_event_name) {
      case "SelectPlatform":
        return (
          <SetNewStore error={error} variables={variables} onClose={onClose} />
        );
      case "ShowBridgeManualDownload":
        return (
          <BridgeManualDownload
            error={error}
            variables={variables}
            onClose={onClose}
          />
        );
      default:
        return (
          <DefaultMap
            error={error}
            variables={variables}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          />
        );
    }
  };

  return <CustomMessage>{renderContent()}</CustomMessage>;
};

export default ErrorMapContent;
