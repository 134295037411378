import React from "react";
import SurveyTicketHeader from "../../modules/Admin/SurveyTicket/components/SurveyTicketHeader";
import AccessFooter from "../../modules/Admin/Access/components/AccessFooter";

import { Container, Label } from "./styles";

const TicketsContainer = ({ data, children, onFooterButton, modalType }) => (
  <Container $modalType={modalType}>
    <SurveyTicketHeader
      title={!modalType ? data?.headerTitle || "" : ""}
      subTitle={data?.headerSubTitle || ""}
      icon={data?.headerIcon || ""}
    />

    {children}

    {modalType && <Label>It's just one click!</Label>}

    {data?.footerButtonText && (
      <AccessFooter
        nextStep={onFooterButton}
        nextStepTitle={data?.footerButtonText}
      />
    )}
  </Container>
);

export default TicketsContainer;
