import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
  width: 100%;
  height: 108px;
  border-top: 0.5px solid rgba(24, 35, 83, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 32px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  width: 264px;
  height: 50px;
  background: ${({ theme }) => theme.pallette.green.light};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.pallette.blue.primary};
  border: none;

  &.ant-btn-default {
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${({ theme }) => theme.pallette.green.secondary};
      border: none;
      color: ${({ theme }) => theme.pallette.white.main};
    }

    &:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${({ theme }) => theme.pallette.green.light};
      border: none;
      color: ${({ theme }) => theme.pallette.blue.primary};
    }
  }
`;

export const TextButton = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.pallette.silver.primary};
  margin-top: 8px;
`;
