import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataForSelect: "",
};

export const adminFilterSlice = createSlice({
  name: "filter",
  initialState,

  reducers: {
    setSelectedData: (state, action) => {
      state.dataForSelect = action.payload;
    },
  },
});

export const { setSelectedData } = adminFilterSlice.actions;

export default adminFilterSlice.reducer;
