import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const MenuHamb = styled.div`
  color: ${({ theme }) => theme.pallette.white.main};
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 20px;
  transition: all 0.5s ease;
  cursor: pointer;

  svg {
    height: 100%;
    width: 60px;
  }

  path {
    fill: ${({ theme }) => theme.pallette.white.main};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.pallette.blue.light};
    }
  }

  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
`;
