import React from "react";

import { Container, Title, SubTitle } from "./styles";

const SurveyTicketHeader = ({ title, subTitle, icon }) => (
  <Container>
    {icon}

    <Title>{title}</Title>

    <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />
  </Container>
);

export default SurveyTicketHeader;
