/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";

import { CustomLink } from "./styles";
import { useGetDownloadBridgeQuery } from "../../../store/wizardCreate/wizardCreateService";
import { downloadWithURL } from "../../../utils";

const BridgeManualDownload = ({ error, variables, onClose }) => {
  const placeholder = `{{${variables?.link?.type}}}`;
  const messageParts = error?.split(placeholder) || [];

  const [clicked, setClicked] = useState(true);
  const { data: dataBridge } = useGetDownloadBridgeQuery({}, { skip: clicked });

  useEffect(() => {
    if (!clicked && dataBridge) {
      downloadWithURL(dataBridge?.payload?.url);
    }
  }, [clicked, dataBridge]);

  const handleClickAndDownload = () => {
    setClicked(false);

    if (dataBridge) {
      downloadWithURL(dataBridge?.payload?.url);
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {messageParts.map((part, index) => (
        <span key={index}>
          <span dangerouslySetInnerHTML={{ __html: part }} />
          {index < messageParts.length - 1 && (
            <CustomLink onClick={handleClickAndDownload}>
              {variables?.link?.text}
            </CustomLink>
          )}
        </span>
      ))}
    </>
  );
};

export default BridgeManualDownload;
