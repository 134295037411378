import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 0 24px;
  width: 100%;

  .ant-collapse {
    width: 100%;
    border: none;
    background-color: ${({ theme }) => theme.pallette.silver.main};
  }

  .ant-collapse-content-box {
    background-color: ${({ theme }) => theme.pallette.silver.main};
  }

  .ant-collapse-header-text {
    color: ${({ theme }) => theme.pallette.blue.primary};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .ant-collapse-item:last-child {
    border: none;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.pallette.blue.primary};
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.5s ease;
  gap: 0 5px;
  display: flex;
  align-items: center;
  padding: 10px 0;

  svg {
    path {
      stroke: ${({ theme }) => theme.pallette.blue.primary};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.pallette.blue.light};

    svg {
      path {
        stroke: ${({ theme }) => theme.pallette.blue.light};
      }
    }
  }
`;
