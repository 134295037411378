import { BaseQueryParams } from "../baseQuery";

export const priceRulesService = BaseQueryParams("priceRulesService", [
  "PriceRules",
]).injectEndpoints({
  endpoints: (builder) => ({
    getPriceRules: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations/rules${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["PriceRules"],
    }),

    deletePriceRule: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/rule/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["PriceRules"],
    }),

    createPriceRule: builder.mutation({
      query: (body) => ({
        url: "/admin/migrations/rule",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PriceRules"],
    }),

    updatePriceRule: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/migrations/rules/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["PriceRules"],
    }),

    getRuleById: builder.query({
      query: (id) => ({
        url: `/admin/migrations/rule/${id}`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["PriceRules"],
    }),

    getUserEmails: builder.query({
      query: ({ email }) => ({
        url: `/migrations/wizard/get-accounts${
          email ? `?search=${encodeURIComponent(email)}` : ""
        }`,
        method: "GET",
      }),
    }),

    getStoresCarts: builder.query({
      query: () => ({
        url: "/migrations/wizard/available-stores",
        method: "GET",
      }),
    }),

    getRuleIntervals: builder.query({
      query: ({ id, page, filters }) => ({
        url: `/admin/migrations/interval-price?per_page=5& ${
          page ? `page=${page}` : ""
        }&config=1`,
        // url: `https://api.newapp.shopping-cart-migration.com/api/admin/price-rules/intervals/${id}?per_page=5& ${
        //   page ? `page=${page}` : ""
        // }`,
        method: "GET",
        params: filters,
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["PriceRules"],
    }),

    deleteRuleInterval: builder.mutation({
      query: (id) => ({
        url: `/admin/migrations/interval-price/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["PriceRules"],
    }),

    createRuleInterval: builder.mutation({
      query: (body) => ({
        url: "/admin/migrations/interval-price",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PriceRules"],
    }),

    updateRuleInterval: builder.mutation({
      query: ({ id, body }) => ({
        url: `//admin/configurations/rules/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["PriceRules"],
    }),
  }),
});

export const {
  useGetPriceRulesQuery,
  useDeletePriceRuleMutation,
  useCreatePriceRuleMutation,
  useUpdatePriceRuleMutation,
  useGetRuleByIdQuery,
  useGetUserEmailsQuery,
  useGetStoresCartsQuery,
  useGetRuleIntervalsQuery,
  useDeleteRuleIntervalMutation,
  useCreateRuleIntervalMutation,
  useUpdateRuleIntervalMutation,
} = priceRulesService;
